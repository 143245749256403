import { getAvatar } from '../helpers/user';

export default function OwnerBlock({ owner }) {
  if (!owner) {
    return null;
  }

  const { data } = owner;
  const avatar = getAvatar(owner);

  return (
    <div className="nft-block -owned-by">
      <img src={require(`../i/a/${avatar}.jpg`)} alt="owner" />
      <h3>Owned by</h3>
      <div>
        <div className="owner">{data.address}</div>
      </div>
    </div>
  );
}
