import './local.scss';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { getAvatar } from '../../helpers/user';
import { AVATARS } from '../../constants/avatars';
import { useForm } from 'react-hook-form';
import { ROUTE_NAME_MY_COLLECTION, NAMED_ROUTES } from '../../routes';
import { getInfo } from '../../services/marketingApi';
import { updateUser, resetUpdated } from '../../store/user/actions';
import { STATUS_SUBSCRIBED } from '../../constants/subscription';
import Loader from '../../components/Loader';

const EditProfile = ({ user, updateUser, resetUpdated, isUpdated, isFetching }) => {
  const backUrl = NAMED_ROUTES[ROUTE_NAME_MY_COLLECTION];
  const [isSubscribed, setSubscribed] = useState(false);

  const defaultValues = {
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      data: {
        avatar: getAvatar(user),
      },
    },
    subscribed: isSubscribed,
    email: user.email,
  };

  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [user, isSubscribed])
  });

  useEffect(() => {
    reset(defaultValues);
  }, [user, isSubscribed]);

  useEffect(() => {
    getInfo().then(({ status }) => setSubscribed(status === STATUS_SUBSCRIBED));
  }, [user]);

  useEffect(() => {
    return () => {
      resetUpdated();
    };
  }, []);

  const onSubmit = (data) => {
    updateUser(data);
  }

  const userAvatar = watch('user.data.avatar');
  const firstName = watch('user.firstName');
  const lastName = watch('user.lastName');

  if (isUpdated) {
    return <Navigate to={backUrl} />;
  }

  return (
    <section className="edit-profile">
      <div className="user">
          <img src={require(`../../i/a/${userAvatar}.jpg`)} />
          <h2>{firstName} {lastName}</h2>
          <div className="email">{user.email}</div>
          <div className="info">
            <h3>Account</h3>
            <p>Manage all aspects of your DNIZN’s account, profile, and digital collectibles.</p>
          </div>
          <div className="link">
            <Link to="/my-collection"><button className="-gradient -wide">My Collection</button></Link>
          </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Edit Profile</h1>

        <h2>Set Avatar</h2>
        <div className="avatars">
          {
            AVATARS.map((avatar) => {
              return (
                <img
                  key={`avatar${avatar}`}
                  className={avatar === userAvatar ? 'current' : null}
                  src={require(`../../i/a/${avatar}.jpg`)}
                  onClick={() => setValue("user.data.avatar", avatar)}
                />
              );
            })
          }
        </div>

        <h2>Informations</h2>
        <div className="info">
          <div className="firstname">
            <label htmlFor="firstname">First Name</label>
            <input id="firstname" {...register('user.firstName', { required: true })} />
          </div>
          <div className="lastname">
            <label htmlFor="lastname">Last Name</label>
            <input id="lastname" {...register('user.lastName', { required: true })} />
          </div>
          <div className="email">
            <label htmlFor="email">Email</label>
            <input id="email" value={user.email} disabled={true} />
          </div>
        </div>

        <h2>Email Preferences</h2>
        <div className="marketing">
          <label htmlFor="mailchimp">
            <input type="checkbox" {...register('subscribed')} />
            I want to recieve news, feature updates, and offers from DNIZN.
          </label>
        </div>

        <div className="buttons">
          <button type="submit" disabled={isFetching} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isFetching && <Loader size={30} color="#ffffff" />}
            {isFetching ? 'Saving...' : 'Save Changes'}
          </button>
          {
            isFetching && <button className="cancel" disabled={isFetching}>Cancel</button>
          }
          {
            !isFetching &&
            <Link to={backUrl}>
              <button className="cancel">Cancel</button>
            </Link>
          }
        </div>
      </form>
    </section>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.info,
  isUpdated: user.isUpdated,
  isFetching: user.isFetching,
});

const mapActionsToProps = dispatch => ({
  updateUser: (email, data) => dispatch(updateUser(email, data)),
  resetUpdated: () => dispatch(resetUpdated()),
});

export default connect(mapStateToProps, mapActionsToProps)(EditProfile);
