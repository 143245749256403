import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {Helmet} from "react-helmet-async";

export default function Terms() {

  return (
    <>
      <Helmet>
        <title>DNIZN - Service Terms</title>
        <meta name="description" content="DNIZN - Service Terms" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DNIZN - Service Terms" />
        <meta property="og:url" content="https://dnizn.com/terms" />
        <meta property="og:image" content="https://dnizn.com/og.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dnizn.com" />
        <meta property="twitter:url" content="https://dnizn.com/terms" />
        <meta name="twitter:title" content="DNIZN - Service Terms" />
        <meta name="twitter:image" content="https://dnizn.com/og.png" />
      </Helmet>
      <section className="legal">
        <h1>DNIZN Service Terms</h1>
        <ol>
          <li>
            <h2>Introduction</h2>
            <ol>
              <li>
                These terms and conditions (<strong>“Agreement”</strong>) governs the registration and use of:
                <ol className="-a">
                  <li>the DNIZN-branded software application and related software application (<strong>“Apps”</strong>); </li>
                  <li>the website http://dnizn.com/ and/or any sub-website and/or associated domains (and/or sub-domains) of http://dnizn.com/ (the “<strong>Site</strong>”); and </li>
                  <li>the services available from us via the Apps and the Site (<strong>“Services”</strong>), </li>
                </ol>
                (together referred to as <strong>“DNIZN”</strong>). 
              </li>

              <li>
                DNIZN is provided by EQUITBL Limited (<strong>“we”</strong>, <strong>“us”</strong>, <strong>“our”</strong>, <strong>“EQUITBL”</strong>), to each of user of DNIZN (<strong>“you”</strong>, <strong>“your”</strong> and <strong>“Member”</strong>). 
              </li>

              <li>This Agreement includes our <Link to="/privacy">Privacy Policy</Link>. You should read this Agreement before agreeing to them and using DNIZN. We recommend that you download a copy for your records. Please see Clause 11 for further details about our Privacy Policy.</li>

              <li>You may be given, or offered the opportunity to buy, a unique character or avatar and/or other digital products non-fungible tokens or ‘NFTs’ or services (<strong>“Products”</strong>) which you will only be able to use to access or participate in various events on DNIZN (e.g., quizzes, games, events and so on, but not any type of gambling or regulated products or services). <strong>Products are subject to certain usage restrictions (see Clause 4.6).</strong> </li>

              <li>We may create Products using distributed ledger technology in order to ensure its integrity, authenticity, ownership and use on DNIZN but EQUITBL is not providing any ‘cryptoasset exchange’ or ‘custodian wallet’ services. The nature of the rights comprised in the Products will be restricted to intellectual property rights, rather than having the characteristics of any form of ‘specified investment’ under the Financial Services and Markets Act 2000 (Regulated Activities) Order 2001 or ‘electronic money’ under the Electronic Money Regulations 2011. When buying a Product, you will own the Product and/or be granted such licences as are necessary for you to use the Product on DNIZN while you are a Member. Your use and enjoyment of the Product you purchase and the related rights will require you to remain a Member. Registration as a Member shall be free of charge, and we shall not charge Members any fees to use DNIZN generally, but we may charge each Seller commission for acting as the Seller’s agent for the resale of Products under Clause 1.7 (<strong>“Commission”</strong>).</li>

              <li>It will not be possible to use or import any non-DNIZN tokens or other digital products or money into DNIZN, or to access any such non-DNIZN products or money via DNIZN. In addition, you must not remove any Product from DNIZN or attempt to sell, transfer or trade any Product outside DNIZN or on any third-party service or exchange.</li>

              <li>Where you hold a Product, you may indicate to us that you wish to sell it to another Member. In that event, in your capacity as the selling Member (“Seller”) you hereby appoint and authorise EQUITBL to act as your commercial agent to negotiate or conclude the sale of the Product to another Member (“Buyer”), only on your behalf and in accordance with  the standard terms and conditions of sale between each Seller and Buyer set out in Annex A (<HashLink to="#a">“Terms of Licence &amp; Sale”</HashLink>) and the provisions of Clause 6 of this Agreement, and to act as merchant of record for card scheme purposes (see Clause 1.9). In this limited capacity, EQUITBL is not a party to the Terms of Licence &amp; Sale, nor is EQUITBL acting as an agent on any Buyer’s behalf. </li>

              <li>When you place an order for a Product from a Seller, you purchase the Product directly from the Seller under the Terms of Licence &amp; Sale (see Clause 5). Where you pay us the price of any Product supplied to you by a Seller (as required by the Terms of Licence &amp; Sale), that payment satisfies your obligation to pay the Seller. This means that if the Seller does not receive payment for the Products in that situation, the Seller must only seek payment from us for the money, not the Buyer. </li>

              <li>Whether you buy Product from us or a Seller through us as the Seller’s commercial agent, EQUITBL is acting as the ‘merchant of record’ to receive payment (pursuant to our authority as agent for Seller where applicable), so you will see our name or DNIZN on your card or payment account statement.</li>

              <li>EQUITBL does not guarantee there will be any Sellers or Buyers on DNIZN or for any particular Product or type of Product and has no responsibility to find or locate any Sellers, Buyers or Products for resale.</li>

              <li>This Agreement shall start on the date on which we notify you that your application to register for DNIZN has been accepted under Clause 3 and DNIZN is available for your use; and shall continue subject to any right to cancel this Agreement under Clause 1.12 or terminate it under Clause 16 (<strong>“Term”</strong>).</li>

              <li>You shall be entitled to cancel this Agreement within the 14 day period that begins on the day after you enter into it (<strong>“Statutory Cancellation Period”</strong>). If you cancel this Agreement during the Statutory Cancellation Period, any Terms of Licence &amp; Sale that you agreed during the Statutory Cancellation Period will also be cancelled automatically. You may exercise your right of cancellation by emailing us to that effect at <a href="mailto:help@dnizn.com">help@dnizn.com</a>.</li>

              <li>DNIZN is intended solely for use by individual people who meet the eligibility criteria set out in Clause 3.1. DNIZN is provided in or from the United Kingdom (“UK”) and is not intended for distribution to, or use by, any person or entity in any jurisdiction where such distribution or use would be contrary to any applicable local law, regulation or rule. You will be accessing DNIZN in the UK.</li>

              <li>This Agreement is concluded in the English language and all communications (including any notices or the information being transmitted) shall be in English. If the Agreement is translated into any other language (whether for your convenience or otherwise), the English language text of the Agreement shall prevail.</li>

              <li>Any questions regarding the service provided through this Agreement should be directed to us at <a href="mailto:help@dnizn.com">help@dnizn.com</a> unless otherwise advised by us.</li>
            </ol>
          </li>



          <li>
            <h2>Information about us</h2>
            <p>EQUITBL Limited is a company incorporated in England and Wales (company number 14038975) with registered office and principal trading address is at 167-169 Great Portland street, 5th Floor, London, W1W 5PF. We are registered with the Information Commissioner (No. ZB406310).</p>
          </li>



          <li>
            <h2>Access to DNIZN</h2>
            <ol>
              <li>In addition to meeting the criteria in Clause 1.8, to be eligible to use DNIZN, you must be an individual person over the age of 18 who is resident in the UK and not resident in any country listed in Annex B and not acting in the course of any business, trade or profession. You must not register on DNIZN on behalf of any other person. Before you can undertake any resales under Clause 1.7 you must first provide to us the details of a nominated payment account at a duly authorized UK financial institution (<strong>“Nominated Payment Account”</strong>) to which the payment of any net sale proceeds can be made by us under Clause 6.</li>

              <li>We agree to provide DNIZN with reasonable skill and care and in accordance with all applicable UK laws and regulations.</li>

              <li>
                We shall use commercially reasonable endeavours to make DNIZN available 24 hours a day, seven days a week, except for routine and emergency maintenance. However, access to DNIZN is not guaranteed. Subject to Applicable Law, from time to time we may: 
                <ol className="-a">
                  <li>withdraw or amend any feature of DNIZN provided without notice;</li>
                  <li>restrict access to some parts of DNIZN under clause 4.2.</li>
                </ol>
              </li>

              <li>If we accept your application to use DNIZN, we will create a data account for you (<strong>“DNIZN Account”</strong>). Your DNIZN Account is not a payment account and does not hold any funds or other assets, but records your transaction information and may store the digital Products you have purchased for use only on or in connection with DNIZN. Each Product that you purchase will be associated with your DNIZN Account for use exclusively on DNIZN. It will not be possible to associate any money, funds or other tokens or assets with DNIZN or your DNIZN Account. The DNIZN Account will not be able to be used, nor shall you use it, as any type of ‘custodian’ or ‘e-money’ account or wallet.</li>

              <li>To access your DNIZN Account you will require a valid email address and will be asked to enter a username, password and any other piece of information we deem necessary as part of our security procedures (<strong>“DNIZN Passcodes”</strong>). You must not impersonate or try to impersonate another person when providing us with your personal information that we collect from you. </li>

              <li>You must keep your DNIZN Passcodes secret and make sure that they are not stored on or near your device or otherwise in a way that enables others to impersonate you; and are memorized and any record of your DNIZN Passcodes destroyed or deleted. You must periodically change your DNIZN Passcodes in accordance with the periods of time and procedures established by us for doing so.</li>

              <li>Each time you seek to access DNIZN, we will check your identity by asking for your DNIZN Passcodes. If the correct DNIZN Passcodes are entered, we will assume that you are the person giving instructions and making transactions and you will be liable for them, except to the extent provided for in clause 3.9.</li>

              <li>If you disclose the DNIZN Passcodes to any other person or entity whom you employ or otherwise retain, appoint or authorise to access DNIZN on your behalf, you are also responsible and liable for any access, use or misuse or disclosure of your DNIZN Passcodes or DNIZN by such person or entity.</li>

              <li>If you think that someone else may have access to, or be using, your DNIZN Passcodes or DNIZN Account without your consent, you must tell us as immediately by emailing <a href="mailto:help@dnizn.com">help@dnizn.com</a>. You will be asked to provide information to enable us to verify your identity. Following satisfactory completion of the verification process, we will immediately prevent further unauthorised use, including blocking the use of the DNIZN Passcodes and issue replacements.</li>

              <li>You certify that all information you provide to us at registration is accurate. You must have a valid email address registered with us at all times. If we become aware that an email that we sent to you did not arrive for any reason, your DNIZN Account may be temporarily suspended until you contact us with a verifiable address.</li>

              <li>We can refuse to act on any instruction that we believe: (i) was unclear; (ii) was not given by or with your authority; (iii) might cause us or any of our partners to breach a legal or other duty; or (iv) involves the use of DNIZN for an illegal purpose.</li>

              <li>
                Subject to the provisions of Clause 14, unless and until you notify us by emailing <a href="mailto:help@dnizn.com">help@dnizn.com</a> that you believe that someone else can use DNIZN by impersonating you:
                <ol className="-a">
                  <li>you will be responsible for any instruction which we receive and act on, even if it was not given by you; and</li>
                  <li>we will not be responsible for any unauthorised access to confidential information about you in DNIZN.</li>
                </ol>
              </li>

              <li>If we believe you have acted fraudulently, or if we believe you have intentionally or with gross negligence failed to keep your DNIZN Passcodes secure and confidential at all times, we will hold you liable for all subsequent transactions and any associated costs.</li>

              <li>We will do all that we reasonably can to prevent unauthorised access to DNIZN. </li>

              <li>Except as required by Applicable Law, we shall not be responsible, and you will be solely responsible, for (a) compiling and retaining permanent records of all your use of DNIZN or your DNIZN Account, and (b) reconciling all transaction activity between your own system or device and your DNIZN Account.</li>

              <li>You are responsible for making all arrangements necessary for you to have access to DNIZN (for example ensuring you have an available internet connection). Unless otherwise agreed by us in writing, you acknowledge and agree that you shall (at your own cost) be solely responsible throughout the Term for the provision of all equipment, software, systems and telecommunications facilities which are required to enable you to access and use DNIZN.</li>
            </ol>
          </li>




          <li>
              <h2>Using DNIZN</h2>
              <ol>
                <li>Unless otherwise specified in this Agreement, to use DNIZN you must log-in to your DNIZN Account with your DNIZN Passcodes and follow the relevant instructions. </li>

                <li>
                  We may restrict or suspend your use of DNIZN without notice: 
                  <ol className="-a">
                    <li>if we, a Seller or any other service provider identifies or suspects that suspicious, fraudulent or illegal activities are being carried out in relation to your use of DNIZN; </li>
                    <li>if we believe you have not complied with this Agreement; or </li>
                    <li>in the event of exceptional circumstances which prohibit the normal operation of DNIZN.  </li>
                  </ol>
                </li>

                <li>Unless it would be unlawful for us to do so or it is impracticable, where we stop or suspend the use of DNIZN in accordance Clause 4.2, we will notify you of this and our reasons for doing so, by sending an email to the email address you have provided to us.  Where it is not possible to notify you before we stop or suspend DNIZN, we will notify you as soon as possible afterwards. We will reactivate your access to DNIZN or re-initiate any suspended payment order as soon as practicable after the reasons pursuant to Clause 4.2 no longer apply or exist.</li>

                <li>We may record calls with you, as well as any use or attempted use of DNIZN and any digital communications with you and, if necessary, to use the recordings as evidence of such calls, sessions or communications.</li>

                <li>Subject to compliance with the provisions of this Agreement at all times, you may enter into additional agreements with third party service providers in order to use their services to take advantage of some features of DNIZN. You are responsible for assessing whether or not those services meet your needs and for making those arrangements; and you do so at your own risk. Neither we nor any Seller shall have any responsibility or liability in connection with any such services or service providers.</li>

                <li>
                  Digital Products available on DNIZN are subject to certain restrictions:
                  <ol className="-a">
                    <li>
                      any cryptographically based token (whether or not a non-fungible token (“NFT”): 
                      <ol className="-i">
                        <li>
                          must only:
                          <ol className="-a -clean">
                            <li>be offered on DNIZN;</li>
                            <li>be created or issued by EQUITBL;</li>
                          </ol>
                        </li>
                        <li>will only grant the holder access to a current or prospective activity within DNIZN; and</li>
                        <li>
                          must not, and cannot:
                          <ol className="-a -clean">
                            <li>be offered to the public generally (i.e. outside DNIZN);</li>
                            <li>be used or accepted as a means of payment by any third party;</li>
                            <li>grant the holder any rights that are the same or substantially similar to any rights granted by any specified financial investment instruments under the Financial Services and Markets Act 2000;</li>
                            <li>be used as a means of raising capital or funding;</li>
                            <li>be traded (other than by way of resale to a Buyer on DNIZN under Clause 1.7).</li>
                          </ol>
                        </li>
                      </ol>
                      A token that meets these conditions shall be referred to under or in connection with this Agreement as a <strong>“Utility Token”</strong>.
                    </li>
                  </ol>
                </li>
              </ol>
          </li>



          <li>
            <h2>Buying Products from or through EQUITBL on DNIZN</h2>
            <ol>
              <li>Where you buy Products from a Seller through us on DNIZN, you will be required to enter your payment details in the course of buying the Product.  In doing so, you may also need to register with a third party payment service provider (<strong>“Payment Service Provider”</strong>) and agree to accept the terms and conditions of the Payment Service Provider (<strong>“Payment Service Agreement”</strong>). </li>

              <li>We reserve the right to restrict, block and/or suspend access to your DNIZN Account until the price payable under the Seller Sale & Service Terms has been paid by you.</li>
            </ol>
          </li>



          <li>
            <h2>Resale of Products on DNIZN</h2>
            <ol>
              <li>In its capacity as commercial agent and merchant of record for Sellers under Clause 1.7, EQUITBL shall be primarily responsible to its Payment Service Provider for managing refund requests or other claims initiated by Buyers or their financial institutions relating to their purchase of Products under the Terms of Licence & Sale (<strong>“Chargebacks”</strong>).  In the event of a refund or Chargeback request, as between EQUITBL and the Seller (consistent with EQUITBL acting as merchant of record and the limited nature of its commercial agency role), the Seller hereby authorises EQUITBL to accept or reject a Buyer’s request for a refund or Chargeback, for which you as the Seller shall be liable.</li>

              <li>The Seller shall pay to EQUITBL from the proceeds of each resale under Clause 1.7 amounts representing: (a) Commission relating to the resale of the Seller’s Product; and (b) any refunds or Chargebacks processed by EQUITBL (in its sole discretion as merchant of record) in relation to any issues raised by Buyers regarding their purchase of Product(s) from the Seller (if applicable); and you hereby authorize EQUITBLe to deduct and pay such sums to its own account at the time of paying the proceeds of any resale to your Nominated Payment Account). </li>

              <li>VAT will be payable in respect of Commission.  </li>

              <li>Details of the Commission and any refunds or Chargebacks that have been charged under Clause 6.2 will be provided in the Seller’s DNIZN Account. </li>

              <li>EQUITBL shall have the right to amend the rates of Commission at any time and/or to amend any other terms of this Agreement under Clause 17. </li>

              <li>If as a Seller you reasonably dispute a refund or Chargeback, you must notify EQUITBL in writing as soon as possible and at the latest within thirty (30) calendar days of such refund or Chargeback being recorded in your DNIZN Account. This shall be dealt with as a complaint under Clause 19. You shall be deemed to have accepted the refund or Chargeback in full if no such notification is received by EQUITBL within the foregoing thirty (30) day time period. </li>

              <li>EQUITBL may, at any time, retain or set off any sums owed to it by the Seller which have fallen due and payable against any sums due to the Seller under this Agreement. Any exercise by EQUITBL of its rights under this clause shall not limit or affect any other rights or remedies available to it under this Agreement or otherwise.</li>

              <li>Equtibl controls any personal data and/or other data that Members provide DNIZN and no Member (whether acting as Seller or Buyer) will be provided with the personal data of any other Member without that other Member’s prior, explicit consent.</li>

              <li>
                Each Seller agrees not to: 
                <ol className="-a">
                  <li>solicit Members to place orders, or pay for Products resold under Clause 1.7 other than via DNIZN in accordance with this Agreement; and </li>
                  <li>offer or solicit or accept (without EQUITBL’s prior written consent) any offer or solicitation from any Members to deal with the Seller in any manner outside DNIZN or in any manner that would breach this Agreement or the Terms of Licence &amp; Sale.</li>
                </ol>
              </li>
            </ol>
          </li>




          <li>
            <h2>Information, not advice</h2>
            <p>Contributions, articles, commentary, charts, data visualisations, text, graphics, still and moving images and other information posted within or available through DNIZN (<strong>“Content”</strong>) is provided for general information only. Content is not, and should not be construed as, financial or other professional advice. You should not rely on the Content within DNIZN as the basis for making a financial decision. If in doubt, you should seek professional advice. Subject to the provisions of Clause 13, we therefore disclaim all liability and responsibility arising from any reliance placed on Content by you or any user of DNIZN, or by anyone who may be informed of any of the Content.</p>
          </li>



          <li>
            <h2>Prohibited uses and countries</h2>
            <ol>
              <li>You may use DNIZN only for lawful purposes.</li>

              <li>
                You must not use DNIZN:
                <ol className="-a">
                  <li>in any way that breaches any Applicable Law;</li>
                  <li>in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</li>
                  <li>for the purpose of harming or attempting to harm any person in any way;</li>
                  <li>to send, knowingly receive, upload, download, use or re-use any material which is defamatory, contains any material which is obscene, offensive, hateful or inflammatory, promotes sexually explicit material, promotes violence, or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                  <li>to send, knowingly receive, upload, download, use or re-use any material which is the private information of any third party, including, without limitation, addresses, phone numbers, email addresses, National Insurance numbers or other identifiers, credit card numbers and/or debit card numbers and/or which is likely to deceive any person, be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence, promote any illegal activity, be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety, be likely to harass, upset, embarrass, alarm or annoy any other person, be used to impersonate any person, or to misrepresent your identity or affiliation with any person, gives the impression that it emanates from us, advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse;</li>
                  <li>to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material, "junk mail", "spam", "chain letters", "pyramid schemes" or any other form of solicitation;</li>
                  <li>to harvest or collect email addresses or other financial, personal or contact information of other users of DNIZN or from DNIZN by electronic or other means for the purposes of sending unsolicited communications;</li>
                  <li>to impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, your financial employment or personal circumstances or your affiliation with any person or entity;</li>
                  <li> to solicit personal information from anyone under 18 or solicit passwords or personal data for commercial or unlawful purposes;</li>
                  <li>in or from a country or territory listed in Annex B to this Agreement (<HashLink to="#b">“Prohibited Countries”</HashLink>).</li>
                </ol>
              </li>

              <li>
                You also agree:
                <ol className="-a">
                  <li>not to use or attempt to use another person's DNIZN Account without authorization from us, or create a false identity in relation to DNIZN;</li>
                  <li>not to do or say anything that would bring DNIZN or any Seller into disrepute.</li>
                </ol>
              </li>
            </ol>
          </li>



          <li>
            <h2>Viruses, hacking and other offences</h2>
            <ol>
              <li>
                You must not: 
                <ol className="-a">
                  <li>misuse DNIZN by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful or any computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                  <li>attempt to gain unauthorised access to DNIZN, the server/servers on which DNIZN or any part of it is stored or any server, device, computer or database connected to DNIZN;</li>
                  <li>attack DNIZN via a denial-of-service attack or a distributed denial-of service attack or in any other way use DNIZN or any Site in any unlawful manner or in any other manner that could damage, disable, overload or impair DNIZN or any Site or the servers on which it is hosted.</li>
                </ol>
                By breaching this provision, you could commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use DNIZN will cease immediately.
              </li>

              <li>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of DNIZN or to your downloading of any material posted on it, or on any website linked to it.</li>

              <li>
                You must not access without authority, interfere with, damage or disrupt: 
                <ol className="-a">
                  <li>any part of DNIZN;</li>
                  <li>any equipment or network on which DNIZN is stored;</li>
                  <li>any software used in the provision of DNIZN; or</li>
                  <li>any equipment or network or software owned or used by any third party;</li>
                </ol>
              </li>
            </ol>
          </li>



          <li>
            <h2>Licence and Intellectual Property Rights</h2>
            <ol>
              <li>We are the owner or the licensee of all Intellectual Property Rights in DNIZN itself, each Product that we offer for sale, and the Content that we provide you via DNIZN (<strong>“DNIZN Content”</strong>). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. For the purpose of this Agreement, <strong>“Intellectual Property Right”</strong> means copyright, database right or trade mark, patent, moral right, design right, registered design, service mark, domain name, unregistered design or other intellectual property right anywhere in the world of any other person.</li>

              <li>When you purchase a Product, you own it to the exclusion of other Members on DNIZN and have the right to sell it to another Member under Clause 1.7. To the extent that your use and enjoyment of your Product on DNIZN may be subject to the Intellectual Property Rights of us or any third party from whom we have received a licence to include their material in the Product, we grant you a licence to use those Intellectual Property Rights in connection with your Product for so long as you are registered as a Member.</li>

              <li>Subject to your rights under Clause 9.2 in relation to Products you purchase, we also grant to you during the Term a non-exclusive, non-transferable, revocable, licence in the UK to use any DNIZN Content for your own personal use on DNIZN, subject to the provisions of this Agreement (and not for use by or for the benefit of any person other than your employee(s)) and not for commercial use or exploitation. You must not modify any Content in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. Our status (and that of any identified contributors) as the authors of the DNIZN Content must always be acknowledged. Nothing in this Clause 9 shall affect your rights under Applicable Law.</li>

              <li>When you post or upload Content to DNIZN (<strong>“Your Content”</strong>), you authorize and direct us to make such copies of it as we consider necessary in order to facilitate the publication, display and storage of Your Content in relation to DNIZN or on the Site. By posting Your Content to any part of DNIZN, you automatically grant, and you represent and warrant that you have the right to grant, to us an irrevocable, perpetual, non-exclusive, transferable, fully paid, worldwide license (with the right to sublicense) to use, copy, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part) and distribute Your Content for any purpose on or in connection with DNIZN or the promotion of it, to prepare derivative works of, or incorporate into other works, Your Content, and to grant and authorize sublicenses of the foregoing. You may remove Your Content from DNIZN at any time, subject to the provisions of Clauses 3.15 and 3.16. If you choose to remove Your Content, the license granted above will automatically expire, however you acknowledge that we may retain archived copies of Your Content.</li>

              <li>You are solely responsible for Your Content. You must not post, transmit, or share Your Content on DNIZN that you did not create or that you do not have permission to display, publish or post. You understand and agree that we may, but are not obliged to, review DNIZN or the Site and may delete or remove (without notice) any of Your Content in our sole and absolute discretion, for any reason or no reason, including without limitation any of Your Content that in our own absolute discretion violates any provision(s) of this Agreement. You are solely responsible at your own cost and expense for creating backup copies and replacing any of Your Content.</li>

              <li>
                You agree, and undertake not to use DNIZN to:
                <ol className="-a">
                  <li>reproduce, duplicate, copy or re-sell any part of DNIZN;</li>
                  <li>reverse engineer or reverse compile any of the technology used to provide you with DNIZN, including but not limited to, any applications or computer programs associated with DNIZN;</li>
                  <li>use DNIZN or the Site in such a way so as to remove the copyright or trade mark notice(s) from any copies of any Content made in accordance with this Agreement;</li>
                  <li>send, knowingly receive, upload, download, use or re-use any material which infringes any Intellectual Property Rights;</li>
                  <li>create a database (electronic or otherwise) that includes material downloaded or otherwise obtained from DNIZN except where expressly permitted under Clause 9.2 of this Agreement);</li>
                  <li>use automated scripts to collect Content from or otherwise interact with DNIZN or the Site;</li>
                  <li>transmit, re-circulate, extract, use, reutilise, exploit, distribute, redistribute, disseminate, re-disseminate, copy or store any Content except where expressly permitted by us on DNIZN or otherwise in writing);</li>
                  <li>promote or attempt to promote or market any goods or services for your own financial benefit.</li>
                  <li>in any way commercially exploit any of the Content without our prior written consent (which may be withheld for any reason): </li>
                  <li>make any of the Content accessible (including the provision of access through a database or other application populated with the Content for re-selling, sub-licensing, transferring or disclosing the Content) by any means, including any electronic means; or</li>
                  <li>combine any Content with other information or adapt the Content wholly or in part.</li>
                </ol>
              </li>

              <li>We may rely on certain service providers to help us to deliver DNIZN to you, for example, third party technology companies who may provide elements of DNIZN’s functionality, (<strong>“Third Party Service Sellers”</strong>). You grant Third Party Service Sellers, a non-exclusive, royalty free and worldwide licence to use use, modify, display, distribute and create derivative materials using Your Content for the purpose of us providing DNIZN to you.</li>
            </ol>
          </li>



          <li>
            <h2>Links by you to and from DNIZN</h2>
            <ol>
              <li>You may link to the home page of DNIZN (and not to any page of DNIZN or Site that is not the home page), provided you do so in a way that is fair and lawful and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. The website from which you are linking must comply in all respects with any content standards set out in this Agreement. DNIZN must not be framed on any other site and you must not display the Content or allow any Content to be displayed surrounded or framed or otherwise surrounded by material not originating from us without our prior written consent.</li>

              <li>If you wish to make any use of material on DNIZN other than that set out above, please address your request to <a href="mailto:help@dnizn.com">help@dnizn.com</a>.</li>

              <li>Where DNIZN contains links to other sites and resources provided by anyone other than us, these links are provided for your information only. We do not recommend and have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them.</li>
            </ol>
          </li>



          <li>
            <h2>Information about you and your use of DNIZN</h2>
            <p>Our Privacy Policy (which is incorporated by reference into this Agreement under Clause 1.3) provides you with the information required by the Data Protection Act 2018 and related acts and regulations governing the use and processing of personal data by persons established in the UK (<strong>“Data Protection Laws”</strong>), including details of the personal data that we collect, as well as the purposes and legal bases for processing such personal data.</p>
          </li>



          <li>
            <h2>Disclaimer of Warranties</h2>
            <ol>
              <li>
                We do not guarantee, warrant or represent that: 
                <ol className="-a">
                  <li>the Content is complete, accurate, up-to-date or error-free;</li>
                  <li>DNIZN is virus-free or that its operation will be continuous, uninterrupted or error-free.</li>
                </ol>
              </li>

              <li>
                You acknowledge and agree that the Content:
                <ol className="-a">
                  <li>cannot be relied upon by you or any third party as a guarantee of any particular result;</li>
                  <li>does not constitute any form of advice, recommendation or endorsement by us;</li>
                  <li>is not intended by us to be relied upon by anyone as the basis for making (or refraining from making) any specific decision; and</li>
                  <li>is used at your own discretion.</li>
                </ol>
              </li>
            </ol>
          </li>




          <li>
            <h2>Limitation of Liability</h2>
            <ol>
              <li>This clause sets out our entire financial liability (including any liability for the acts or omissions of our employees, agents and subcontractors) to you under or in connection with this Agreement; any use made by you of DNIZN or any part of it; and any representation, statement or tortious act or omission (including negligence) arising under or in connection with this Agreement. </li>
              
              <li>Nothing in this Agreement limits or excludes our liability for death or personal injury caused by our negligence, for fraud or fraudulent misrepresentation or any other liability we cannot legally limit.</li>

              <li>Each party shall only be liable for foreseeable loss or damage arising from its own breach of contract, negligence or willful misconduct.</li>

              <li>
                You acknowledge and accept that:
                <ol className="-a">
                  <li>DNIZN is subject to any constraints or limitations stipulated by any regulatory authority or Applicable Law; and</li>
                  <li>our ability to provide DNIZN depends on the continued provision of essential components provided by Third Party Service Sellers including but not limited to providers of processing and other services, over which we have no control.</li>
                </ol>
              </li>

              <li>Notwithstanding anything else contained in this Agreement (except Clause 14.2), neither party shall be liable to the other for any loss of profits, opportunity, business, reputation, data, goodwill or contracts or for any indirect or other loss or damage that is not foreseeable, whether arising from negligence, breach of contract or any other cause of action arising out of the subject matter of this Agreement.</li>

              <li>Neither party shall be liable for any failure or delay in performance of its obligations under this Agreement for causes beyond its reasonable control. The party wishing to claim relief by reason of any such circumstance shall notify the other party in writing without delay on the intervention and on the cessation thereof. </li>

              <li>You are solely and exclusively responsible for any results obtained from your use of DNIZN, and for conclusions drawn from such use. We shall have no liability for any damage caused by errors or omissions in any Content, or instructions or scripts provided to us by you or by any third party in connection with DNIZN.</li>
            </ol>
          </li>




          <li>
            <h2>Indemnification</h2>
            <p>You agree to compensate us against any and all claims, losses, liabilities, damages, expenses and costs (including legal fees and expenses) arising out of or in connection with your use of DNIZN or conduct in relation to it in breach of this Agreement, Your Content or infringement of third party Intellectual Property Rights, except to the extent that we have breached this Agreement or been negligent.</p>
          </li>



          <li>
            <h2>Term and Termination</h2>
            <ol>
              <li>You may terminate the Agreement by notifying us in advance at any time.</li>
              <li>We may terminate the Agreement by notifying you not later than 2 (two) months in advance.</li>
              <li>
                In addition, either party may terminate this Agreement immediately if the other party:
                <ol className="-a">
                  <li>becomes unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986), admits its inability to pay its debts or otherwise becomes insolvent;</li>
                  <li>has any distraint, execution, attachment, sequestration or similar action taken, levied or enforced against itself or any of its substantial assets, or if any garnishee order is issued or served on the party;</li>
                  <li>becomes the subject of any petition presented, order made or resolution passed for the liquidation, administration, bankruptcy or dissolution of all or a substantial part of the party’s business, except where solvent amalgamation or reconstruction is proposed on terms previously approved by the non-terminating party; </li>
                  <li>loses full and unrestricted control over all or part of its assets because of the appointment of an administrative or other receiver, manager, trustee, liquidator, administrator or similar person or officer; or</li>
                  <li>enters into or proposes any composition or arrangement concerning its debts with its creditors (or any class of its creditors).</li>
                </ol>
              </li>

              <li>
                We may terminate this Agreement immediately on written notice to you if:
                <ol className="-a">
                  <li>we suspect that you or a person related to you of money laundering or terrorist financing;</li>
                  <li>you have breached any of the provisions of Clause 3.10 (concerning the accuracy of data you provide to us); </li>
                  <li>we can demonstrate that you have repeatedly breached this Agreement; or</li>
                  <li>termination of the Agreement is demanded by legal or regulatory obligation or a regulatory or governmental authority,</li>
                </ol>
                in such cases, we shall provide you, without undue delay, a statement of the reasons for that decision.
              </li>

              <li>Termination of this Agreement shall not prejudice either of the parties' rights and remedies which have accrued as at termination (provided that you acknowledge, for the avoidance of doubt, that your ownership, use and enjoyment of each Product is conditional upon you remaining a Member). If you wish to dispute the basis for termination, you may complain to us under Clause 19.</li>

              <li>Upon termination of the Agreement, you shall immediately pay to us all amounts owed by you under the Agreement and we shall immediately pay you all amounts owed to you under the Agreement. We shall be entitled to set-off amounts owed by us to you against amounts owed by you to us.</li>

              <li>Upon the termination of this Agreement for any reason, we shall have no obligation to you to store, retain, report, or otherwise provide any copies of, or access to, any Product, records, documentation or other Content in connection with DNIZN (but we may do so to comply with our obligations under Applicable Law).</li>

              <li>Clauses 1, 2, 7, 9, 10, 11, 12, 13, 14, 15, 16.5 – 16.8, 18, 19, 20 and 21 shall survive termination of this Agreement.</li>
            </ol>
          </li>


          <li>
            <h2>Variations to this Agreement</h2>
            <ol>
              <li>Subject to Clauses 17.2, we may periodically make changes to this Agreement and shall notify you by posting a revised version of the Agreement on the Site and emailing you at your email address registered with us. The revised terms including the changes will take effect 14 days following such notice. You will be deemed to have accepted the changes if you do not notify us before the proposed date that the changes take effect that the changes are not accepted. If you reject the changes, you can immediately terminate this Agreement in accordance with Clause 16.1 free of charge and with effect at any time until the date when the changes would have applied (subject to Clause 16.6).</li>

              <li>
                We will only make changes:
                <ol className="-a">
                  <li>where we consider that a change will make these this Agreement clearer and no less favourable to you; or</li>
                  <li>to reflect new, or changes to existing, systems, technology, products, services or business processes; or</li>
                  <li>to help meet the cost of changes in our funding or working capital requirements; or</li>
                  <li>to implement changes required by applicable law, industry codes of practice or decisions of any court, arbitrator or ombudsman.</li>
                </ol>
              </li>

              <li>
                The notice period set out in Clause 17.1 (and purposes referred to in Clause 17.2 shall not apply) where we:
                <ol className="-a">
                  <li>are subject to a legal or regulatory obligation which requires us to change the Agreement within that notice period;</li>
                  <li>have to change the Agreement in exceptional circumstances to address or defend against an unforeseen and imminent danger to the DNIZN, the Services, Members or Sellers from fraud, malware, spam, data breaches or other cybersecurity risks; </li>
                  <li>need to correct an obvious mistake; or</li>
                  <li>need to reflect any new feature, product or service where notice of the change would conflict with our legitimate marketing interests concerning the launch or release and/or the change is reasonably necessary to enable you to use such new feature, product or service.</li>
                </ol>
              </li>
            </ol>
          </li>



          <li>
            <h2>Notices</h2>
            <ol>
              <li>Notices served under this Agreement shall be in writing and may be sent by email, via your DNIZN Account, or by post. The preferred method of communication is email.</li>

              <li>
                Notices shall be sent to:
                <ol className="-a">
                  <li>
                    In the case of DNIZN, in writing to:
                    <blockquote>
                      EQUITBL Limited<br />
                      167-169 Great Portland street,<br />
                      5th Floor,<br />
                      London, W1W 5PF<br />
                      Email: <a href="mailto:help@dnizn.com">help@dnizn.com</a>
                    </blockquote>
                  </li>
                  <li>in your case, in writing to the address or email address provided to us on registration, as updated by you from time to time.</li>
                </ol>
              </li>

              <li>The parties shall notify each other of any change in their contact details for notices as set out in this clause.</li>

              <li>Notices sent by post will be deemed to have been received upon the expiration of two (2) Business Days after posting. Emails will be deemed to have been received one hour after being sent or, if this falls after close of business, at 9.00 a.m. on the following working day provided that an undeliverable message has not been generated by then.</li>
            </ol>
          </li>


          <li>
            <h2>Complaints</h2>
            <p>Any complaints about us or DNIZN must be addressed to us in the first instance by contacting <a href="mailto:help@dnizn.com">help@dnizn.com</a>. We will make every possible effort to reply, addressing all points raised, within an adequate timeframe and at the latest within 15 Business Days of receipt of the complaint, in a durable medium. In exceptional situations, if the answer cannot be given within 15 Business Days for reasons beyond our control, we shall send a holding reply, clearly indicating the reasons for a delay and specifying the deadline by which you will receive the final reply, which shall not exceed 35 Business Days. For the purpose of this Clause “durable medium” means a form which enables you to store the information in a way that is accessible for future reference for a period of time adequate for the purposes of the information and which allows the unchanged reproduction of the information stored.</p>
          </li>




          <li>
            <h2>General Terms</h2>
            <ol>
              <li>These Terms comprise the entire agreement between you and us for the provision of DNIZN and supersede all prior or contemporaneous negotiations, discussions, representations or agreements, whether written or oral. No undertakings, promises, representations, or warranties shall have any legal effect unless expressly set out in this Agreement.</li>
              <li>If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, the other provisions shall remain in force. The invalid or unenforceable provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.</li>
              <li>Nothing in this Agreement shall give rise to any joint venture, partnership, or employment relationship between you and us. Where we act as your agent, we shall only do so to the limited extent specified on the contractual basis of this Agreement and not as your general agent or fiduciary.</li>
              <li>Nothing in this Agreement is intended to confer a benefit on any person who is not a party, and no such person shall have any right under the Contracts (Rights of Third Parties) Act 1999 to enforce any terms of this Agreement, provided that this Clause does not affect a right or remedy of a third party which exists or is available apart from that Act.</li>
              <li>No failure or delay by a party to exercise any right or remedy provided under this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.</li>
            </ol>
          </li>



          <li>
            <h2>Jurisdiction and applicable law</h2>
            <ol>
              <li>This Agreement shall be governed by and construed in accordance with the law of England and Wales.</li>

              <li>The English courts will have exclusive jurisdiction over any claim arising from, or related to, use of DNIZN although we retain the right to bring proceedings against you for breach of these conditions in your country of residence or any other relevant country, and you have the right to ask for proceeding to be heard in the courts of Northern Ireland or Scotland if you live there.</li>
            </ol>
          </li>
        </ol>

        <p>Last updated: 19th of October 2022</p>
      </section>


      <section id="a" className="legal">
        <h2>Annex A<br />DNIZN Terms of Licence & Sale</h2>

        <p>Introduction: where to find information about us and our Products</p>

        <strong>
          <p>You can find everything you need to know about us, EQUITBL Limited (“we”, “us”, “our”, “EQUITBL”), on DNIZN before you order. We also confirm the key information to you in writing before you order in your DNIZN Account. Words starting with a capital letter in these Terms of Licence & Sale that are not defined where they first appear shall have the meaning given in the DNIZN Service Terms.</p>
          <p>When buying a Product, you will own the Product and/or be granted such licences as are necessary for you to use the Product on DNIZN while you are a Member. Your use and enjoyment of the Product you purchase and the related rights will require you to remain a Member.  </p>
          <p>You must not remove any Product from DNIZN or attempt to sell, transfer or trade any Product outside DNIZN or on any third-party service or exchange. Please see Clause 4.6 of the DNIZN Service Terms for further restrictions on Products.</p>
          <p>Our Role in Resale of Products on DNIZN</p>
          <p>Where you are reselling the Product to another Member or purchasing the Product from a Seller on DNIZN, please see Clauses 1.7 to 1.10 and Clauses 5 and 6 of the DNIZN Service Terms.  </p>
          <p>Under Clause 1.7 of the DNIZN Service Terms, each Seller appoints and authorises EQUITBL to act as the Seller’s commercial agent to negotiate or conclude the sale of the Product to the Buyer in accordance with these Terms of Licence & Sale and the provisions of Clause 6 of the DNIZN Service Terms, and to act as merchant of record for card scheme purposes. In this limited capacity, EQUITBL is not a party to the Terms of Licence & Sale and is not acting as an agent on any Buyer’s behalf. </p>
          <p>We may charge each Seller a Commission for acting as the Seller’s agent for the resale of Products.</p>
          <p>As a Buyer, when you place an order for a Product from a Seller, you purchase the Product directly from the Seller under these Terms of Licence & Sale (see Clause 5 of the DNIZN Service Terms). Where you pay us the price of any Product supplied to you by a Seller, that payment satisfies your obligation to pay the Seller. This means that if the Seller does not receive payment for the Product in that situation, the Seller must only seek payment from us for the money, not the Buyer. </p>
          <p>Whether you buy Product from us or a Seller through us as the Seller’s commercial agent, EQUITBL is acting as the ‘merchant of record’ to receive payment (pursuant to our authority as agent for Seller where applicable), so you will see our name or DNIZN on your card or payment account statement. Sellers are consumers and not acting in the course of any business, trade or profession so are not liable to provide Buyers with any of the statutory or regulatory protections usually afforded to consumers.</p>
        </strong>

        <p>When you buy from or through us you are agreeing that:</p>
        <ul>
          <li>We only accept orders when we have checked them.</li>
          <li>Sometimes we reject orders.</li>
          <li>We charge you when the Product is supplied.</li>
          <li>We charge interest on late payments.</li>
          <li>We pass on increases in VAT.</li>
          <li>We are not responsible for delays outside our control.</li>
          <li>We may charge you if you do not give us information we need or do preparatory work as agreed with us .</li>
          <li>You have a legal right to change your mind.</li>
          <li>You can end an on-going contract (find out how).</li>
          <li>You have rights if there is something wrong with your Product.</li>
          <li>We can change Products and these terms.</li>
          <li>We can suspend supply (and you have rights if we do).</li>
          <li>We can withdraw Products.</li>
          <li>We can end our contract with you.</li>
          <li>We do not compensate you for all losses caused by us or our Products.</li>
          <li>We use your personal data as set out in our Privacy Notice.</li>
          <li>You have several options for resolving disputes with us.</li>
          <li>Other important terms apply to our contract. </li>
        </ul>

        <p>We only accept orders when we have checked them </p>

        <p><strong>We contact you to confirm we have received your order and we accept it when we supply the Product to you. </strong></p>

        <p>Sometimes we reject orders</p>

        <p><strong>Sometimes we reject orders, for example, because you are located outside the territories where we supply Products as stated on DNIZN or because the Product was mispriced by us. When this happens, we let you know as soon as possible and refund any sums you have paid.</strong></p>

        <p>We charge you when Products are supplied </p>

        <p><strong>However, for some Products we take payment at regular intervals, as explained to you during the order process. </strong></p>

        <p>We charge interest on late payments</p>

        <p><strong>If we are unable to collect any payment you owe us we charge interest on the overdue amount at the rate of 8% a year above the Bank of England base rate from time to time. This interest accrues on a daily basis from the due date until the date of actual payment of the overdue amount, whether before or after judgment. You pay us the interest together with any overdue amount. </strong></p>

        <p>We pass on increases in VAT</p>

        <p><strong>If the rate of VAT changes between your order date and the date we supply the Product, we adjust the rate of VAT that you pay, unless you have already paid in full before the change in the rate of VAT takes effect.</strong></p>

        <p>We are not responsible for delays outside our control </p>

        <p><strong>If the supply of your Product is delayed by an event outside our control, such as telecommunications or technology disruption, we contact you as soon as possible to let you know and do what we can to reduce the delay. As long as we do this, we will not compensate you for the delay, but if the delay is likely to be substantial you can contact <a href="mailto:help@dnizn.com">help@dnizn.com</a> to end the contract and receive a refund for any Products you have paid for in advance, but not received, less reasonable costs we have already incurred. </strong></p>

        <p>We may charge you if you do not give us information we need or do preparatory work as agreed with us</p>

        <p><strong>We may charge you additional sums if you do not give us information we have asked for about how we can provide the Product or if you don't do preparatory work to prepare for the Product, as agreed with us. </strong></p>

        <p>You have a legal right to change your mind</p>

        <strong>
          <p>Your legal right to change your mind. You have 14 days after the date when we supply a Product to change your mind about the purchase, but:</p>
          <ul>
            <li>You lose the right to cancel any service, when it's been completed (and you must pay for any services provided up the time you cancel).</li>
            <li>You lose the right to cancel your purchase of any digital Product, when you start to download or stream it.</li>
          </ul>
          <p>How to let us know and what happens next. If you change your mind contact <a href="mailto:help@dnizn.com">help@dnizn.com</a>. We refund you as soon as possible and within 14 days of you telling us you've changed your mind. We refund you by the method you used for payment. We don't charge a fee for the refund.</p>
        </strong>


        <p>You can end an on-going contract (find out how)</p>
        <p><strong>We tell you when and how you can end an on-going contract with us (for example, for regular services or a subscription to digital content) during the order process and we confirm this information to you in writing after we've accepted your order. If you have any questions, please contact <a href="mailto:help@dnizn.com">help@dnizn.com</a>.</strong></p>

        <p>You have rights if there is something wrong with your Product</p>
        <p><strong>If you think there is something wrong with your Product, you must contact <a href="mailto:help@dnizn.com">help@dnizn.com</a>. Your legal rights are summarised below. These are subject to certain exceptions, and will not apply where you purchase a Product from a Seller. For detailed information please visit the Citizens Advice website www.citizensadvice.org.uk. Remember too that You have several options for resolving disputes with us.</strong></p>

        <div className="-bordered">
          <h3>Summary of your key legal rights</h3>
          <p>If your Product is <strong>digital content</strong> the Consumer Rights Act 2015 says digital content must be as described, fit for purpose and of satisfactory quality:</p>
          <strong>
            <ul>
              <li>If your digital content is faulty, you're entitled to a repair or a replacement.</li>
              <li>If the fault can't be fixed, or if it hasn't been fixed within a reasonable time and without significant inconvenience, you can get some or all of your money back. </li>
              <li>If you can show the fault has damaged your device and we haven't used reasonable care and skill, you may be entitled to a repair or compensation</li>
            </ul>
          </strong>

          <p>If your Product is <strong>services</strong>, the Consumer Rights Act 2015 says:</p>
          <strong>
            <ul>
              <li>You can ask us to repeat or fix a service if it's not carried out with reasonable care and skill, or get some money back if we can't fix it.</li>
              <li>If a price hasn't been agreed upfront, what you're asked to pay must be reasonable.</li>
              <li>If a time hasn't been agreed upfront, it must be carried out within a reasonable time.</li>
            </ul>
          </strong>
        </div>

        <p>We can change Products and these terms</p>
        <strong>
          <p>Changes we can always make. We can always change a Product:</p>
          <ul>
            <li>to reflect changes in relevant laws and regulatory requirements such as restrictions on cryptoassets or activities related to cryptoassets;</li>
            <li>to make minor technical adjustments and improvements, for example to address a security threat. These are changes that are unlikely to adversely affect your use of the Product; and</li>
            <li>to update digital content, provided that the digital content always matches the description of it that we provided to you before you bought it. We might ask you to install these updates.</li>
          </ul>
          <p>Changes we can only make if we give you notice and an option to terminate. We can also make the following types of change to the Product or these terms, but if we do so we'll notify you and you can then contact <a href="mailto:help@dnizn.com">help@dnizn.com</a> to end the contract before the change takes effect and receive a refund for any Products you've paid for in advance, but not received:</p>
          <ul><li>changes in the rights to use or resell the Products.</li></ul>
        </strong>

        <p>We can suspend supply (and you have rights if we do) </p>
        <strong>
          <p>We can suspend the supply of a Product. We do this to:</p>
          <ul>
            <li>deal with technical problems or make minor technical changes;</li>
            <li>update the Product to reflect changes in relevant laws and regulatory requirements; or</li>
            <li>make changes to the Product (see We can change Products and these terms).</li>
          </ul>
          <p>We let you know, may adjust the price and may allow you to terminate. We contact you in advance to tell you we're suspending supply, unless the problem is urgent or an emergency. If we suspend the Product for more than 14 days, we may adjust the price so you don't pay for it while its suspended. If we suspend supply, or tell you we're going to suspend supply, for more than 14 days, help@dnizn.com to end the contract and we'll refund any sums you've paid in advance for Products you won't receive.</p>
        </strong>

        <p>We can withdraw Products</p>
        <strong>
          <p>We can stop providing a Product, such as an ongoing service or a subscription for digital content. We let you know at least 14 days in advance and we refund any sums you've paid in advance for Products which won't be provided.</p>
        </strong>

        <p>We can end our contract with you</p>
        <strong>
          <p>We can end our contract with you for a Product and claim any compensation due to us if:</p>
          <ul>
            <li>you don't make any payment to us when it's due and you still don't make payment within 14 days of our reminding you that payment is due;</li>
            <li>you do not, within a reasonable time of us asking for it, provide us with information, cooperation or access that we need to provide the Product, for example, identity information where required by law;</li>
            <li>you are in material breach the provisions of these Terms or the DNIZN Service Terms.</li>
          </ul>
        </strong>

        <p>We don't compensate you for all losses caused by us or our Products</p>
        <strong>
          <p>We are responsible for losses you suffer caused by us breaking this contract unless the loss is:</p>
          <ul>
            <li>Unexpected. It was not obvious that it would happen and nothing you said to us before we accepted your order meant we should have expected it (so, in the law, the loss was unforeseeable).</li>
            <li>Caused by a delaying event outside our control. As long as we have taken the steps set out in the section We're not responsible for delays outside our control. </li>
            <li>Avoidable. Something you could have avoided by taking reasonable action. For example, damage to your own digital content or device, which was caused by digital content we supplied and which you could have avoided by following our advice to apply a free update or by correctly following the installation instructions or having the minimum system requirements advised by us.</li>
            <li>A business loss. It relates to your use of a Product for the purposes of your trade, business, craft or profession.</li>
          </ul>
        </strong>

        <p>We use your personal data as set out in our Privacy Notice</p>
        <strong>
          <p>How we use any personal data you give us is set out in our <Link to="/privacy">Privacy Policy</Link>.</p>
        </strong>

        <p>You have several options for resolving disputes with us</p>
        <strong>
          <p>Our complaints policy. Please see Clause 19 of the DNIZN Service Terms.</p>
          <p>You can go to court. Please see Clause 21 of the DNIZN Service Terms.</p>
        </strong>

        <p>Other important terms apply to our contract</p>
        <strong>
          <p>We can transfer our contract with you, so that a different organisation is responsible for supplying your Product. We'll tell you in writing if this happens and we'll ensure that the transfer won't affect your rights under the contract. If you're unhappy with the transfer you can contact <a href="mailto:help@dnizn.com">help@dnizn.com</a> to end the contract and we will refund you any payments you've made in advance for Products not provided.</p>
          <p>You can only transfer your contract with us to someone else if we agree to this. We will not agree if the person to whom you want to transfer the contract is not eligible under the DNIZN Service Terms. </p>
          <p>Nobody else has any rights under this contract. This contract is between you and us or a Seller (as the case may be), and is not intended to benefit any person who is not a party to it. Nobody else can enforce it (except EQUITBL in its capacity as commercial agent of the Seller and merchant of record, as the case may be) and neither of us will need to ask anybody else to sign-off on ending or changing it.</p>
          <p>If a court invalidates some of this contract, the rest of it will still apply. If a court or other authority decides that some of these terms are unlawful, the rest will continue to apply.</p>
          <p>Even if we delay in enforcing this contract, we can still enforce it later. We might not immediately chase you for not doing something (like paying) or for doing something you're not allowed to, but that does not mean we cannot do it later.</p>
        </strong>
      </section>

      

      <section id="b" className="legal">
        <h2>Annex B<br />Prohibited Countries</h2>
        <p>Countries in respect of which the UK has imposed financial sanctions from time to time, as published at: <a href="https://www.gov.uk/government/collections/financial-sanctions-regime-specific-consolidated-lists-and-releases" target="_blank">https://www.gov.uk/government/collections/financial-sanctions-regime-specific-consolidated-lists-and-releases</a>, the current list as at date of last amendment of this Agreement being:</p>
        <ul>
          <li>Afghanistan</li>
          <li>Belarus</li>
          <li>Bosnia and Herzegovina</li>
          <li>Burundi</li>
          <li>Central African Republic</li>
          <li>Democratic People’s Republic of Korea</li>
          <li>Democratic Republic of the Congo</li>
          <li>Guinea</li>
          <li>Republic of Guinea-Bissau</li>
          <li>Iran </li>
          <li>Iraq</li>
          <li>Lebanon</li>
          <li>Libya</li>
          <li>Mali</li>
          <li>Myanmar</li>
          <li>Nicaragua</li>
          <li>Russia</li>
          <li>Somalia</li>
          <li>South Sudan</li>
          <li>Sudan</li>
          <li>Syria</li>
          <li>Venezuela</li>
          <li>Yemen</li>
          <li>Zimbabwe</li>
        </ul>
      </section>
    </>
  )

};