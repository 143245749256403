const LIFETIME_HOURS = 5;

const getExpiredAt = () => {
    const currentDate = new Date();

    return (new Date(new Date(currentDate).setHours(currentDate.getHours() + LIFETIME_HOURS))).valueOf();
}

export const getToken = (tokenId) => {
    if (typeof localStorage === 'undefined') {
        return null;
    }

    const tokens = JSON.parse(getItem('tokens') || '{}');

    return tokens[tokenId];
};

export const setToken = (tokenId, token) => {
    if (!tokenId || !token) {
        return;
    }

    const tokens = JSON.parse(getItem('tokens') || '{}');

    tokens[tokenId] = token;
    setItem('tokens', JSON.stringify(tokens));
}

export const removeToken = () => removeItem('tokens');

export const getNft = (tokenId) => {
    if (typeof localStorage === 'undefined') {
        return null;
    }

    const nft = JSON.parse(getItem('nft') || '{}');

    if (!nft.expiredAt || nft.expiredAt <= Date.now()) {
        removeNft();

        return null;
    }

    return nft.list[tokenId];
};

export const setNft = (tokenId, token) => {
    if (!tokenId || !token) {
        return;
    }

    let nft = JSON.parse(getItem('nft') || '{}');

    if (!nft.list) {
        nft = {
            expiredAt: getExpiredAt(),
            list: {},
        };
    }

    nft.list[tokenId] = token;
    setItem('nft', JSON.stringify(nft));
}

export const removeNft = () => removeItem('nft');

export const getLastUrl = () => {
    if (typeof localStorage === 'undefined') {
        return null;
    }

    return getItem('lastUrl');
};

export const setLastUrl = (lastUrl) => {
    if (!lastUrl) {
        return;
    }

    setItem('lastUrl', lastUrl);
}

export const removeLastUrl = () => removeItem('lastUrl');

export const setCookieWarningClosed = () => {
    setItem('cookieWarningsClosed', 'true');
}

export const getCookieWarningClosed = () => {
    return !!getItem('cookieWarningsClosed');
}

const setItem = (key, value) => {
    return localStorage.setItem(key, value);
}

const getItem = (key) => {
    return localStorage.getItem(key);
}

const removeItem = (key) => {
    return localStorage.removeItem(key);
}
