import withAuthentication from './helpers/withAuthentication';

import Home from './pages/home/Home';
import About from './pages/about/About';
import BuyNft from './pages/buyNft/BuyNft';
import Collection from './pages/collection/Collection';
import CollectionNft from './pages/collection-nft/CollectionNft';
import Cookies from './pages/cookies/Cookies';
import EditProfile from './pages/edit-profile/EditProfile';
import MyCollection from './pages/myCollection/MyCollection';
import OauthCallback from './pages/oauth2/OauthCallback';
import OauthMiddleware from './pages/oauth2/OauthMiddleware';
import Nft from './pages/nft/Nft';
import NftOwner from './pages/nft/NftOwner';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import NotFound from './pages/notFound/NotFound';

export const ROUTE_NAME_HOME = "home";
export const ROUTE_NAME_ABOUT = "about";
export const ROUTE_NAME_BUY_NFT = "buy_nft";
export const ROUTE_NAME_COLLECTION = "collection";
export const ROUTE_NAME_COLLECTION_NFT = "collection_nft";
export const ROUTE_NAME_COOKIES = "cookies";
export const ROUTE_NAME_EDIT_PROFILE = "edit-profile";
export const ROUTE_NAME_MY_COLLECTION = "my_collection";
export const ROUTE_NAME_OAUTH_CALLBACK = "oauth_callback";
export const ROUTE_NAME_OAUTH_MIDDLEWARE = "oauth_middleware";
export const ROUTE_NAME_NFT_OWNER = "nft_owner";
export const ROUTE_NAME_NFT = "nft";
export const ROUTE_NAME_PRIVACY = "privacy";
export const ROUTE_NAME_TERMS = "terms";
export const ROUTE_NAME_NOT_FOUND = "not_found";

export const NAMED_ROUTES = {
  [ROUTE_NAME_HOME]: "/",
  [ROUTE_NAME_ABOUT]: "/about",
  [ROUTE_NAME_BUY_NFT]: "/nft/buy",
  [ROUTE_NAME_COLLECTION]: "/collection",
  [ROUTE_NAME_COLLECTION_NFT]: "/collection/nft",
  [ROUTE_NAME_COOKIES]: "/cookies",
  [ROUTE_NAME_EDIT_PROFILE]: "/edit-profile",
  [ROUTE_NAME_MY_COLLECTION]: "/my-collection",
  [ROUTE_NAME_NFT_OWNER]: "/nft/owner",
  [ROUTE_NAME_NFT]: "/nft/:id",
  [ROUTE_NAME_OAUTH_CALLBACK]: "/oauth2/callback/:id",
  [ROUTE_NAME_OAUTH_MIDDLEWARE]: "/oauth2/middleware",
  [ROUTE_NAME_PRIVACY]: "/privacy",
  [ROUTE_NAME_TERMS]: "/terms",
  [ROUTE_NAME_NOT_FOUND]: "*",
};

export default [
  {
    path: NAMED_ROUTES[ROUTE_NAME_HOME],
    exact: true,
    element: Home,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_ABOUT],
    exact: true,
    element: About,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_COLLECTION],
    exact: true,
    element: Collection,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_COLLECTION_NFT],
    exact: true,
    element: CollectionNft,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_COOKIES],
    exact: true,
    element: Cookies,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_MY_COLLECTION],
    exact: true,
    element: withAuthentication(MyCollection),
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_EDIT_PROFILE],
    exact: true,
    element: withAuthentication(EditProfile),
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_OAUTH_CALLBACK],
    exact: true,
    element: OauthCallback,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_OAUTH_MIDDLEWARE],
    exact: true,
    element: OauthMiddleware,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_BUY_NFT],
    exact: true,
    element: withAuthentication(BuyNft),
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_NFT_OWNER],
    exact: true,
    element: withAuthentication(NftOwner),
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_NFT],
    exact: true,
    element: Nft,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_PRIVACY],
    exact: true,
    element: Privacy,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_TERMS],
    exact: true,
    element: Terms,
  },
  {
    path: NAMED_ROUTES[ROUTE_NAME_NOT_FOUND],
    exact: true,
    element: NotFound,
  },
];
