import { getLastUrl, removeLastUrl } from '../services/storage';
import { Navigate } from 'react-router-dom';

export const setRedirectToLastUrl = (setRedirectTo, setParentRedirect) => {
  const lastUrl = getLastUrl();

  setRedirectTo(lastUrl || '/');
  setParentRedirect(true);
  removeLastUrl();
}

export const getRedirectTo = (redirectTo, isParentRedirect) => {
  const isAbsolute = redirectTo.toLowerCase().indexOf('http://') === 0 || redirectTo.toLowerCase().indexOf('https://') === 0;
  const isParent = isParentRedirect && window.parent;

  if (isAbsolute || isParent) {
    window.parent.location.href = redirectTo;

    return null;
  }

  return <Navigate to={redirectTo} />;
}
