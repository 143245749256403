import { EUR, GBP, USD, getCurrencySymbol } from '../../helpers/currency';

const PriceSelector = ({ currency, price, onChangeCurrency }) => {
  return (
    <div className="price-selector">
      <div className="price">{getCurrencySymbol(currency)}{price}</div>
      <div className="currency">
        <button className={currency === GBP ? '-selected' : null} onClick={() => onChangeCurrency(GBP)}>
          {getCurrencySymbol(GBP)} {GBP}
        </button>
        <button className={currency === EUR ? '-selected' : null} onClick={() => onChangeCurrency(EUR)}>
          {getCurrencySymbol(EUR)} {EUR}
        </button>
        <button className={currency === USD ? '-selected' : null} onClick={() => onChangeCurrency(USD)}>
          {getCurrencySymbol(USD)} {USD}
        </button>
      </div>
    </div>
  );
};

export default PriceSelector;
