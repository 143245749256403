export const USD = 'USD';
export const EUR = 'EUR';
export const GBP = 'GBP';

const currencySymbols = {
  [USD]: '$',
  [EUR]: '€',
  [GBP]: '£',
};

export const getDefaultCurrency = () => {
  return process.env.REACT_APP_DEFAULT_CURRENCY;
}

export const getCurrencySymbol = (currency) => {
  return currencySymbols[currency];
}

export const calculatePrice = (price, currency, rates) => {
  const rate = rates[currency] || 1;

  return parseFloat((price * rate).toFixed(2));
}
