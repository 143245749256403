import { Link } from 'react-router-dom';
import './local.scss';
import { getNftPrice } from '../../helpers/nft';

export default function CollectionNft() {

  return (
    <>
      <section className="collection-nft">
        <div className="image">
          <img src={require('../../i/dummy/nft.jpg')} />
        </div>
        <div className="info">
          <h1>Lorem ipsum dolor sit </h1>
          <div className="price">
            <div>${getNftPrice()}</div>
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
          <p>
            <Link to="/nft/buy"><button className="-gradient">Buy Now</button></Link>
          </p>
          <div className="collection-block">
            <img src={require('../../i/dummy/collection.jpg')} />
            <h3>Collection</h3>
            <p>Lorem ipsum dolor sit amet</p>
          </div>
          <div className="owned-by-block">
            <img src={require('../../i/a/14.jpg')} />
            <h3>Owned by</h3>
            <div>
              <h3>Vassily Poupkine</h3>
              <p>Latest Transaction:<br />0x8ec0ec5efa2ed8eefdbd986671f3e5a23011d983c235a4a89d46df1219973793</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h3 className="-gradient">Description</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet. sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </section>
    </>
  );

};
