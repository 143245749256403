import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getNftList, nftClear } from '../../store/nft/actions';
import { getAvatar } from '../../helpers/user';
import Video from '../../components/Video/Video';
import Error from '../../components/Error';

import './local.scss';

const TwitterLink = ({ link }) => {
  return (
    <a href={link} target="_blank">
      <button className="-twitter">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.6425 4.93708C22.8075 5.30708 21.9105 5.55708 20.9675 5.67008C21.9405 5.08787 22.6685 4.17154 23.0155 3.09208C22.1014 3.63507 21.1009 4.01727 20.0575 4.22208C19.3559 3.47294 18.4266 2.9764 17.4138 2.80955C16.4011 2.6427 15.3616 2.81487 14.4568 3.29933C13.5519 3.78379 12.8323 4.55344 12.4097 5.48878C11.9871 6.42412 11.8851 7.47283 12.1195 8.47208C10.2672 8.37907 8.45515 7.89763 6.80095 7.05898C5.14674 6.22034 3.68736 5.04324 2.51752 3.60408C2.11752 4.29408 1.88752 5.09408 1.88752 5.94608C1.88708 6.71307 2.07596 7.46832 2.4374 8.14481C2.79885 8.8213 3.32168 9.39812 3.95952 9.82408C3.2198 9.80054 2.4964 9.60066 1.84952 9.24108V9.30108C1.84945 10.3768 2.22156 11.4195 2.90271 12.2521C3.58386 13.0847 4.53209 13.656 5.58652 13.8691C4.90031 14.0548 4.18086 14.0821 3.48252 13.9491C3.78002 14.8747 4.35952 15.6841 5.13989 16.264C5.92026 16.8439 6.86244 17.1653 7.83452 17.1831C6.18436 18.4785 4.14641 19.1812 2.04852 19.1781C1.6769 19.1782 1.3056 19.1565 0.936523 19.1131C3.066 20.4823 5.54486 21.2089 8.07652 21.2061C16.6465 21.2061 21.3315 14.1081 21.3315 7.95208C21.3315 7.75208 21.3265 7.55008 21.3175 7.35008C22.2288 6.69105 23.0154 5.87497 23.6405 4.94008L23.6425 4.93708Z" fill="#ffffff"/></svg>
      </button>
    </a>
  )
}

const DummyNft = ({ nft }) => {
  const { animation_url, name, id } = nft;

  return (
    <div className="nft">
      <div className="collection">
        <img src={require('../../i/collection.png')} />
        <h3>Collection</h3>
        <h4>UmbroID</h4>
      </div>
      <div className="asset">
        <Video src={animation_url} autoPlay muted loop playsInline />
        <h3>{name}</h3>
      </div>
      <div className="actions">
        <Link to={`/nft/${id}`}><button>Details</button></Link>
        <TwitterLink link={`https://twitter.com/intent/tweet?text=Check%20out%20my%20%23UmbroID%20Digital%20Collectible%20from%20%23Umbro.%0AI%E2%80%99m%20now%20a%20%23DNIZN!%20https://dnizn.com/nft/${id}`} />
      </div>
    </div>
  )
}

const MyCollection = ({ user, wallet, getNftList, nftClear, nftList, error }) => {
  useEffect(() => {
    if (wallet) {
      getNftList(wallet);
    }

    return () => {
      nftClear();
    }
  }, []);

  if (error) {
    console.log(error);

    return <Error />;
  }

  return (
    <>
      { user &&
        <>
          <section className="user">
            <img src={require(`../../i/a/${getAvatar(user)}.jpg`)} />
            <h2>{user.firstName} {user.lastName}</h2>
            <div className="email">{user.email}</div>
            <div className="edit">
              <Link to="/edit-profile">
                <button>Edit Profile</button>
              </Link>
            </div>
          </section>
          <section className="user-collection">
            <h2>My Collectibles</h2>
            <div>
              {nftList.map((nft) => {
                return <DummyNft key={`token${nft.id}`} nft={nft} />
              })}
            </div>
          </section>
        </>
      }
    </>
  );
};

const mapStateToProps = ({ user, nft }) => ({
  user: user.info,
  wallet: user.wallet,
  nftList: nft.nftList,
  error: nft.error,
});

const mapActionsToProps = dispatch => ({
  getNftList: (wallet) => dispatch(getNftList(wallet)),
  nftClear: () => dispatch(nftClear()),
});

export default connect(mapStateToProps, mapActionsToProps)(MyCollection);
