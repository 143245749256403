import React from 'react';
import { Routes, Route } from 'react-router-dom';

function renderRoutes(routes) {
  return routes ? (
    <Routes>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          element={<route.element />}
        />
      ))}
    </Routes>
  ) : null;
}

export default renderRoutes;
