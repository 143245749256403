import { API_REQUEST } from "../constants";
import {
    payByCard as payByCardRequest,
} from '../../services/paymentApi';
import { PAYMENT_PAY_BY_CARD, PAYMENT_CLEAR } from "./constants";

export const payByCard = (data) => {
    return {
        type: PAYMENT_PAY_BY_CARD + API_REQUEST,
        prefix: PAYMENT_PAY_BY_CARD,
        filter: API_REQUEST,
        request: () => payByCardRequest(data),
    };
}

export const paymentClear = () => {
    return {
        type: PAYMENT_CLEAR,
    };
}
