import { ethers } from 'ethers';
import contractAbi from './contract/abi.json';
import { getNft, setNft } from './storage';

const rpcNode = process.env.REACT_APP_BLOCKCHAIN_RPC_NODE;
const chainId = parseInt(process.env.REACT_APP_BLOCKCHAIN_CHAIN_ID);
const contractAddress = process.env.REACT_APP_BLOCKCHAIN_CONTRACT_ADDRESS;

const provider = new ethers.providers.JsonRpcProvider(rpcNode, {
  chainId,
});

const contract = new ethers.Contract(contractAddress, contractAbi, provider);

export const getNFTInfo = async (tokenId, ownerAddress = null) => {
  let token = getNft(tokenId);

  if (!!token?.name) {
    return token;
  }

  token = await fetchNftInfo(tokenId, ownerAddress);
  token = {
    id: tokenId,
    owner: ownerAddress,
    name: null,
    description: null,
    animation_url: null,
    ...token,
  };

  setNft(tokenId, token);

  return token;
}

const fetchNftInfo = async (tokenId, ownerAddress) => {
  const owner = ownerAddress ? ownerAddress : await contract.ownerOf(tokenId).catch((error) => onNftFetchError(error, null));
  const tokenURI = await contract.tokenURI(tokenId).catch((error) => onNftFetchError(error, null));

  if (tokenURI === null) {
    return {};
  }

  const nft = await fetch(tokenURI).then(response => response.json()).catch((error) => onNftFetchError(error, {}));

  nft.owner = owner;

  return nft;
}

const onNftFetchError = (error, defaultValue) => {
  console.log(error);

  return defaultValue;
}
