import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import  { connect } from 'react-redux';
import { getOauthTokenFirst, getOauthTokenSecond } from '../../store/auth/actions';
import { TOKEN_ID_FIRST, TOKEN_ID_SECOND } from './constants';
import { getRedirectTo, setRedirectToLastUrl } from '../../helpers/authRedirect';
import Error from '../../components/Error';

const OauthCallback = ({ getOauthTokenFirst, getOauthTokenSecond, tokenRequested, error }) => {
  let { id } = useParams();
  const [redirectTo, setRedirectTo] = useState(null);
  const [isParentRedirect, setParentRedirect] = useState(false);
  const [search] = useSearchParams();
  const code = search.get('code');
  const state = search.get('state');

  useEffect(() => {
    if (id === TOKEN_ID_FIRST) {
      getOauthTokenFirst(code);
    }

    if (id === TOKEN_ID_SECOND) {
      getOauthTokenSecond(code);
    }
  }, [code]);

  useEffect(() => {
    if (id === TOKEN_ID_FIRST && tokenRequested && error === null) {
      setRedirectTo(`/oauth2/middleware${state ? `?state=${state}` : ''}`);
    }

    if (id === TOKEN_ID_SECOND && tokenRequested && error === null) {
      setRedirectToLastUrl(setRedirectTo, setParentRedirect);
    }
  }, [code, tokenRequested, error]);

  if (error) {
    return <Error />;
  }

  if (redirectTo !== null) {
    return getRedirectTo(redirectTo, isParentRedirect);
  }

  return null;
};

const mapStateToProps = ({ auth }) => ({
  tokenRequested: auth.tokenRequested,
  isFetching: auth.isFetching,
  error: auth.error,
});

const mapActionsToProps = dispatch => ({
  getOauthTokenFirst: (code) => dispatch(getOauthTokenFirst(code)),
  getOauthTokenSecond: (code) => dispatch(getOauthTokenSecond(code)),
});

export default connect(mapStateToProps,mapActionsToProps)(OauthCallback);
