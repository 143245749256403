import { API_REQUEST } from "../constants";
import {
    getOauthTokenFirst as getOauthTokenFirstRequest,
    getOauthTokenSecond as getOauthTokenSecondRequest,
} from '../../services/authApi';
import {
    AUTH_LOGOUT,
    AUTH_OAUTH_TOKEN_FIRST,
    AUTH_OAUTH_TOKEN_SECOND,
    AUTH_CLEAR_ERRORS,
    AUTH_CHECK,
} from './constants';
import { removeToken } from '../../services/storage';

export const getOauthTokenFirst = code => {
    return {
        type: AUTH_OAUTH_TOKEN_FIRST + API_REQUEST,
        prefix: AUTH_OAUTH_TOKEN_FIRST,
        filter: API_REQUEST,
        request: () => getOauthTokenFirstRequest(code),
    };
};

export const getOauthTokenSecond = code => {
    return {
        type: AUTH_OAUTH_TOKEN_SECOND + API_REQUEST,
        prefix: AUTH_OAUTH_TOKEN_SECOND,
        filter: API_REQUEST,
        request: () => getOauthTokenSecondRequest(code),
    };
};

export const authCheck = () => {
    return { type: AUTH_CHECK };
};

export const logout = () => {
    removeToken();

    return { type: AUTH_LOGOUT };
};

export const clearErrors = () => {
    return { type: AUTH_CLEAR_ERRORS };
};
