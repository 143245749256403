import { API_REQUEST } from "../constants";
import {
    getRates as getRatesRequest,
} from '../../services/currencyExchangeApi';
import { CURRENCY_RATES, CURRENCY_CLEAR } from "./constants";

export const getRates = (from) => {
    return {
        type: CURRENCY_RATES + API_REQUEST,
        prefix: CURRENCY_RATES,
        filter: API_REQUEST,
        request: () => getRatesRequest(from),
    };
}

export const currencyClear = () => {
    return {
        type: CURRENCY_CLEAR,
    };
}
