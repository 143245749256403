import {
    AUTH_CLEAR_ERRORS,
    AUTH_LOGOUT,
    AUTH_OAUTH_TOKEN_FIRST,
    AUTH_OAUTH_TOKEN_SECOND,
} from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    tokenRequested: false,
    isLoggedOut: false,
    error: null,
    isFetching: false
};

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AUTH_OAUTH_TOKEN_FIRST + API_REQUEST:
        case AUTH_OAUTH_TOKEN_SECOND + API_REQUEST:
            return {
                ...state,
                isFetching: true,
                isLoggedOut: false,
            };
        case AUTH_OAUTH_TOKEN_FIRST + API_SUCCESS:
        case AUTH_OAUTH_TOKEN_SECOND + API_SUCCESS:
            return {
                ...state,
                tokenRequested: true,
                isFetching: false,
                error: null,
            };
        case AUTH_OAUTH_TOKEN_FIRST + API_ERROR:
        case AUTH_OAUTH_TOKEN_SECOND + API_ERROR:
            return {
                ...state,
                error: payload,
                isFetching: false,
                tokenRequested: true,
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                isLoggedOut: true,
            };
        case AUTH_CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};
