import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore from './store/configureStore';
import renderRoutes from './helpers/renderRoutes';
import routes from './routes';
import { install } from 'ga-gtag';
import {Helmet, HelmetProvider} from "react-helmet-async";

import ScrollToTop from './components/ScrollToTop';
import Layout from './layout/Layout';
import './styles/main.scss';

const store = configureStore();

function App() {
  install('G-F92V94302P');
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Helmet>
            <title>Become a DNIZN Today: UmbroID Digital Collectibles Available Now</title>
            <meta name="description" content="DNIZN is a community for collectors. The marketplace for #SmartDigitalCollectibles from your favourite brands. Connect. Collect. Become a DNIZN." />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Become a DNIZN Today: UmbroID Digital Collectibles Available Now" />
            <meta
              property="og:description"
              content="DNIZN is a community for collectors. The marketplace for #SmartDigitalCollectibles from your favourite brands. Connect. Collect. Become a DNIZN."
            />
            <meta property="og:url" content="https://dnizn.com" />
            <meta property="og:image" content="https://dnizn.com/og.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="dnizn.com" />
            <meta property="twitter:url" content="https://dnizn.com" />
            <meta name="twitter:title" content="Become a DNIZN Today: UmbroID Digital Collectibles Available Now" />
            <meta name="twitter:description" content="DNIZN is a community for collectors. The marketplace for #SmartDigitalCollectibles from your favourite brands. Connect. Collect. Become a DNIZN." />
            <meta name="twitter:image" content="https://dnizn.com/og.png" />
          </Helmet>
          <Layout>
            {renderRoutes(routes)}
          </Layout>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
