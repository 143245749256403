export const getErrorMessage = (error) => {
  if (!error?.response) {
    return !!error?.message ? error?.message : error;
  }

  if (!!error?.response?.data?.error) {
    return error.response.data.error.message;
  }

  return error?.response?.data?.message || 'Something went wrong, please try again later.';
}
