import thunkMiddleware from 'redux-thunk';
import { configureStore as createStore } from '@reduxjs/toolkit';
import api from './middleware/api';
import auth from './middleware/auth';
import user from './middleware/user';
import reducer from './rootReducer';

const configureStore = preloadedState => {
    return createStore({
        reducer,
        preloadedState,
        middleware: [thunkMiddleware, api, auth, user],
    });
};

export default configureStore;
