import './local.scss';
import { connect } from 'react-redux';

const NftOwner = ({ user }) => {

  return (
    <>
      <section>
        <h3 style={{ textAlign: 'center' }}>{!!user.data.nftOwner ? 'We checked your account and we have assigned a new role for you.' : 'We checked your account and there are no new roles for you.'}</h3>
      </section>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.info,
});

export default connect(mapStateToProps)(NftOwner);
