import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_STRIPE_API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

export const payByCard = ({ cardNumber, expMonth, expYear, cvc, billingDetails }) => {
  const params = {
    type: 'card',
    'card[number]': cardNumber,
    'card[exp_month]': expMonth,
    'card[exp_year]': expYear,
    'card[cvc]': cvc,
    'billing_details[address][line1]': billingDetails.address.line1,
    'billing_details[address][line2]': billingDetails.address.line2,
    'billing_details[address][city]': billingDetails.address.city,
    'billing_details[address][state]': billingDetails.address.state,
    'billing_details[address][postal_code]': billingDetails.address.postal_code,
    'billing_details[address][country]': billingDetails.address.countryCode,
    'billing_details[name]': billingDetails.name,
    'billing_details[email]': billingDetails.email,
    'billing_details[phone]': billingDetails.phone,
  };

  return axios.post(`/payment_methods?${new URLSearchParams(params).toString()}`).then(response => response.data);
}
