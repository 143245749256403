import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { subscribe } from '../services/marketingApi';
import { STATUS_SUBSCRIBED, STATUS_SENDING, STATUS_ERROR } from '../constants/subscription';

export default function Subscribe() {
  const { register, handleSubmit } = useForm();
  const [status, setStatus] = useState(null);
  const isSending = status === STATUS_SENDING;

  const onSubmit = ({ email }) => {
    setStatus(STATUS_SENDING);

    subscribe(email)
      .then(({ status }) => {
        if (status === STATUS_SUBSCRIBED) {
          setStatus(STATUS_SUBSCRIBED);
        }
      })
      .catch(() => setStatus(STATUS_ERROR));
  }

  if (status === STATUS_SUBSCRIBED) {
    return <div className="feedback">Thank you, we will keep you updated with our new drops</div>;
  }

  return (
    <>
      <h3>Get The Latest Updates</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="email" placeholder="Email Address" {...register('email')} readOnly={isSending} />
        <button type="submit" disabled={isSending}>I'm in</button>
      </form>
    </>
  );
}
