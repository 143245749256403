import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './auth/reducer';
import { userReducer } from './user/reducer';
import { paymentReducer } from './payment/reducer';
import { nftReducer } from './nft/reducer';
import { discordReducer } from './discord/reducer';
import { currencyReducer } from './currency/reducer';

const appReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    payment: paymentReducer,
    discord: discordReducer,
    nft: nftReducer,
    currency: currencyReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
