import React, { useState, useRef, useEffect } from 'react';

import './carousel.scss';

export const Card = ({src}) => (
    <div className="card">
        <img src={src} />
    </div>
);
  
export const Carousel = ({children}) => {
    const BREAKPOINT = 640;
    const getWindowSize = () => {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const [active, setActive] = useState(0);
    const count = React.Children.count(children);
    const [auto, setAuto] = useState(windowSize.innerWidth >= BREAKPOINT);

    const timerRef = useRef(null);

    useEffect(() => {
        if(timerRef.current){
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if(auto) {
                setActive(active => active < count - 1 ? (active + 1) : 0);
            }
        }, 2000);
        return () => clearTimeout(timerRef.current);
    }, [active]);

    const handleButton = (active) => {
        setActive(active);
        setAuto(false);
    }

    return (
        <div className="carousel">
        {active > 0 && <button className="left" onClick={() => handleButton(i => i - 1)}>&lt;</button>}
        {React.Children.map(children, (child, i) => (
            windowSize.innerWidth >= BREAKPOINT ? 
            <div className="card-container" style={{
                '--active': i === active ? 1 : 0,
                '--offset': (active - i) / 3,
                '--direction': Math.sign(active - i),
                '--abs-offset': Math.abs(active - i) / 3,
                'pointerEvents': active === i ? 'auto' : 'none',
                'opacity': Math.abs(active - i) >= 2 ? '0' : '1',
                'display': Math.abs(active - i) > 2 ? 'none' : 'block',
            }}>
            {child}
            </div> : <>{child}</>
        ))}
        {active < count - 1 && <button className="right" onClick={() => handleButton(i => i + 1)}>&gt;</button>}
        </div>
    );
};