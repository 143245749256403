import { API_SUCCESS } from '../constants';
import { AUTH_CHECK, AUTH_OAUTH_TOKEN_FIRST, AUTH_OAUTH_TOKEN_SECOND } from '../auth/constants';
import { getToken, setLastUrl, setToken } from '../../services/storage';
import { TOKEN_ID_FIRST, TOKEN_ID_SECOND } from '../../pages/oauth2/constants';
import { fetchUser } from '../user/actions';
import { authFirstUrl } from '../../helpers/authUrl';

const actionTypeTokenIdMap = {
    [AUTH_OAUTH_TOKEN_FIRST + API_SUCCESS]: TOKEN_ID_FIRST,
    [AUTH_OAUTH_TOKEN_SECOND + API_SUCCESS]: TOKEN_ID_SECOND,
};

const auth = ({ dispatch }) => next => action => {
    next(action);

    const { type, payload } = action;
    const tokenId = actionTypeTokenIdMap[type];

    if (tokenId && payload.data) {
        const { data: { access_token } } = payload;

        if (access_token) {
            setToken(tokenId, access_token);
        }
    }

    if (type === AUTH_CHECK) {
        const firstToken = getToken(TOKEN_ID_FIRST);

        if (!firstToken) {
            setLastUrl(window.location.pathname + window.location.search);
            window.location.href = authFirstUrl();
        } else {
            dispatch(fetchUser());
        }
    }
};

export default auth;
