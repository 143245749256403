import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import CookiesWarning from './CookiesWarning';

export default class Layout extends Component {

  render() {

    return (
      <>
        <Header />

        <div className="body">
          {this.props.children}
        </div>

        <Footer />
        <CookiesWarning />
      </>
    );
  }

}
