import { API_REQUEST } from "../constants";
import {
    allocWallet as allocWalletRequest,
} from '../../services/api';
import {
    getUser as getUserRequest,
    updateUser as updateUserRequest,
    updateUserData as updateUserDataRequest,
} from '../../services/authApi';
import {
    USER_ALLOC_WALLET,
    USER_CHECK_WALLET,
    USER_FETCH,
    USER_UPDATE,
    USER_UPDATE_DATA,
    USER_RESET_UPDATED,
} from "./constants";

export const fetchUser = () => {
    return {
        type: USER_FETCH + API_REQUEST,
        prefix: USER_FETCH,
        filter: API_REQUEST,
        request: () => getUserRequest(),
    };
}

export const updateUser = (data) => {
    return {
        type: USER_UPDATE + API_REQUEST,
        prefix: USER_UPDATE,
        filter: API_REQUEST,
        request: () => updateUserRequest(data),
    };
}

export const updateUserData = (data) => {
    return {
        type: USER_UPDATE_DATA + API_REQUEST,
        prefix: USER_UPDATE_DATA,
        filter: API_REQUEST,
        request: () => updateUserDataRequest(data),
    };
}

export const resetUpdated = () => {
    return { type: USER_RESET_UPDATED };
};

export const allocWallet = (tokenFirst, tokenSecond) => {
    return {
        type: USER_ALLOC_WALLET + API_REQUEST,
        prefix: USER_ALLOC_WALLET,
        filter: API_REQUEST,
        request: () => allocWalletRequest(tokenFirst, tokenSecond),
    };
};

export const checkWallet = () => {
    return { type: USER_CHECK_WALLET };
};
