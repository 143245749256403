import { NFT_BUY, NFT_LIST, NFT_GET, NFT_CLEAR, NFT_OWNER, NFT_COUNT_IN_STOCK } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    nftBought: null,
    nftList: [],
    nft: null,
    nftCountInStock: null,
    nftOwner: null,
    error: null,
    isFetching: false,
};

export const nftReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case NFT_BUY + API_REQUEST:
            return { ...state, isFetching: true };
        case NFT_BUY + API_SUCCESS:
            return {
                ...state,
                nftBought: payload,
                nftOwner: null,
                isFetching: false,
            };
        case NFT_BUY + API_ERROR:
            return {
                ...state,
                nftBought: null,
                isFetching: false,
                error: payload,
            };
        case NFT_LIST + API_REQUEST:
            return { ...state, isFetching: true };
        case NFT_LIST + API_SUCCESS:
            return {
                ...state,
                nftList: payload,
                isFetching: false,
            };
        case NFT_LIST + API_ERROR:
            return {
                ...state,
                nftList: [],
                isFetching: false,
                error: payload,
            };
        case NFT_GET + API_REQUEST:
            return { ...state, isFetching: true };
        case NFT_GET + API_SUCCESS:
            return {
                ...state,
                nft: payload,
                isFetching: false,
            };
        case NFT_GET + API_ERROR:
            return {
                ...state,
                nft: null,
                isFetching: false,
                error: payload,
            };
        case NFT_OWNER + API_REQUEST:
            return { ...state, isFetching: true };
        case NFT_OWNER + API_SUCCESS:
            return {
                ...state,
                nftOwner: payload,
                isFetching: false,
            };
        case NFT_OWNER + API_ERROR:
            return {
                ...state,
                nftOwner: null,
                isFetching: false,
                error: payload,
            };
        case NFT_COUNT_IN_STOCK + API_REQUEST:
            return { ...state, isFetching: true };
        case NFT_COUNT_IN_STOCK + API_SUCCESS:
            return {
                ...state,
                nftCountInStock: payload,
                isFetching: false,
            };
        case NFT_COUNT_IN_STOCK + API_ERROR:
            return {
                ...state,
                nftCountInStock: null,
                isFetching: false,
                error: payload,
            };
        case NFT_CLEAR:
            return {
                ...state,
                nftBought: null,
                nftOwner: null,
                error: null,
            };
        default:
            return state;
    }
};
