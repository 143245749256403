import {Helmet} from "react-helmet-async";

import './local.scss';
import Roadmap from './roadmap.svg';

export default function About() {

  const Card = ({children}) => {
    return(
      <div className="card">
        <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="64" height="64" rx="32" fill="#BE2D33"/><g clipPath="url(#a)" fill="#fff"><path d="M51.946 13.055C46.75 7.86 39.846 5 32.5 5c-7.345 0-14.251 2.86-19.445 8.055C7.86 18.248 5 25.154 5 32.5c0 7.345 2.86 14.251 8.055 19.446C18.248 57.14 25.154 60 32.5 60c7.345 0 14.251-2.86 19.446-8.054C57.14 46.75 60 39.846 60 32.5c0-7.345-2.86-14.251-8.054-19.445ZM32.5 56.776c-13.387 0-24.277-10.89-24.277-24.277S19.113 8.223 32.5 8.223 56.777 19.113 56.777 32.5 45.887 56.777 32.5 56.777Z"/><path d="M38.126 26.237 32.5 14.838l-5.626 11.4-12.58 1.827 9.103 8.873-2.149 12.528L32.5 43.551l11.252 5.915-2.15-12.528 9.103-8.873-12.58-1.828Zm1.346 17.338L32.5 39.91l-6.971 3.665 1.33-7.763-5.639-5.497 7.795-1.132L32.5 22.12l3.486 7.063 7.794 1.132-5.64 5.498 1.331 7.762Z"/></g><defs><clipPath id="a"><path transform="translate(5 5)" d="M0 0h55v55H0z"/></clipPath></defs></svg>
        {children}
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>About DNIZN - Want To Know More About Becoming a DNIZN?</title>
        <meta name="description" content="Find out everything there is to know about DNIZN, the platform focused on bringing consumers and brands together through digital collectibles." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About DNIZN - Want To Know More About Becoming a DNIZN?" />
        <meta
          property="og:description"
          content="Find out everything there is to know about DNIZN, the platform focused on bringing consumers and brands together through digital collectibles."
        />
        <meta property="og:url" content="https://dnizn.com/about" />
        <meta property="og:image" content="https://dnizn.com/og.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dnizn.com" />
        <meta property="twitter:url" content="https://dnizn.com/about" />
        <meta name="twitter:title" content="About DNIZN - Want To Know More About Becoming a DNIZN?" />
        <meta name="twitter:description" content="Find out everything there is to know about DNIZN, the platform focused on bringing consumers and brands together through digital collectibles." />
        <meta name="twitter:image" content="https://dnizn.com/og.png" />
      </Helmet>
      <div className="common-hero"> 
        <section>
          <div className="text">
            <h2>Smart digital collectibles from your favourite brands</h2>
            <p>It’s time to redefine NFTs. Make them mainstream. Authentic. And guaranteed.</p>
            <p>Using Web3 technology, we help brands do what they've always done - reward consumers with unique collectibles - except now, they’re no longer physical assets. They’re digital.</p>
            <p>DNIZN is for the collectors. It’s the digital home of your favourite brands. Connect with them. Collect from them. Direct from them.</p>
            <p>The world needs a safe digital space for people and brands to inhabit, explore, and engage.</p>
            <p><em>This</em> is DNIZN.</p>
          </div>
          <div className="image" />
        </section>
      </div>

      <section>
        <h2>DNIZN Defined</h2>
        <p><em><strong>DNIZN. (/ˈden.ɪ.zen/: a person, animal, or plant that lives or is found in a particular place.)</strong></em></p>
        <p>The world of NFTs and Crypto can be a bit of a minefield. Hard to get your head around. Largely unregulated. Extremely unstable and unpredictable. And full of horror stories.</p>
        <p>DNIZN is not “Crypto”. It’s not just for the “Crypto” community. It’s for everyone. Any collector. </p>
        <p>All are welcome on DNIZN.</p>
        <p>It’s a safe digital marketplace. With the warmth and familiarity of an online Comic-Con. Where brands offer digital rewards and collectibles directly to their consumers. And consumers feel safe in the knowledge that the NFTs they are collecting are authentic, original, and secure.</p>
        <p>And like any marketplace, or convention centre, DNIZN is driven by the interactions between brands and their consumers. It’s a two-way conversation. You speak. They listen. They speak. You’ll be the first to know. And the first in line for the latest Digital Collectibles. Direct from your favourite brands.</p>
        <p>DNIZN is a protected space.</p>
        <p>Our secure Primary Marketplace means that brands and consumers can safely transition into the digital collectibles market without the fear of being scammed or ripped off. By replicating physical collectibles as Digital Collectibles, these digital assets can be tracked in perpetuity. Meaning provenance is always guaranteed. You’ll be able to prove it’s an authentic brand collectible and not a cheap knock-off.</p>
        <p>Plus, Digital Collectibles within our marketplace can’t be removed and traded elsewhere. Only internal trades will be allowed within DNIZN. And everyone is rewarded for their active participation in the community.</p>
        <p>DNIZN is your digital habitat. And you, our DNIZNs, are its inhabitants. We’re a community of brands and consumers. </p>
        <p><em><strong>You</strong></em> are DNIZN.</p>
      </section>

      <section className="roadmap">
        <h2>Roadmap</h2>
        <div className="image"><img width="100%" src={Roadmap} /></div>
      </section>

      <section className="benefits">
        <h2>Community Benefits</h2>
        <p>We’re harnessing the passion people have for sport, music, and entertainment, and engaging with their favourite brands, by giving something back. Loyalty and engagement are rewarded. Our DNIZNs enjoy all these community benefits:</p>
        <div className="cards">
          <Card>
            <h3>No gas fees</h3>
            <p>Enjoy purchasing your digital collectibles without worrying about added gas fees. Not now. Not ever.</p>
          </Card>
          <Card>
            <h3>No hidden Transaction fees</h3>
            <p>No surprises at checkout, our transaction fees are always upfront.</p>
          </Card>
          <Card>
            <h3>Exclusive brand giveaways</h3>
            <p>Enjoy exclusive brand giveaways and be part of a new movement.</p>
          </Card>
        </div>
      </section>
    </>
  );

};
