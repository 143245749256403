import { useState, useEffect } from 'react';
import { BigPlayButton, ControlBar, Player, Shortcut } from 'video-react';

import "video-react/dist/video-react.css";
import './video.scss';

const Video = ({ src, autoPlay, muted, loop, playsInline, playerRef = null, onVideoEnd = () => {} }) => {
  const [videoState, setVideoState] = useState({});
  const [hasEnded, setHasEnded] = useState(false);

  useEffect(() => {
    playerRef?.current?.subscribeToStateChange(setVideoState);
  }, [setVideoState]);

  useEffect(() => {
    if (videoState && videoState.ended && !hasEnded) {
      setHasEnded(true);
      onVideoEnd();
    }
  }, [videoState, hasEnded]);

  return (
    <Player
      ref={playerRef}
      src={src}
      autoPlay={autoPlay}
      muted={muted}
      loop={loop}
      playsInline={playsInline}
    >
      <BigPlayButton position="center" className="hidden" />
      <ControlBar disableCompletely={true} />
      <Shortcut clickable={false} dblclickable={false} />
    </Player>
  );
};

export default Video;
