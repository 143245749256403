import Axios from 'axios';
import { getNFTInfo } from './blockchain';

const baseURL = process.env.REACT_APP_API_URL;
const axios = Axios.create({
  baseURL,
});

export const allocWallet = (tokenFirst, tokenSecond) => {
  return axios.get('/users/alloc-wallet', {
    headers: {
      'x-user-token-1': tokenFirst,
      'x-user-token-2': tokenSecond,
    }
  });
}

export const getNftCountInStock = () => {
  return axios.get('/blockchain/nft/count-in-stock')
    .then(response => parseInt(response.data));
}

export const buyNft = (buyerAddr, paymentMethodId, amount, currency) => {
  return axios.post('/payments/buy-nft', {
      buyerAddr,
      paymentMethodId,
      amount: amount * 100,
      currency,
    })
    .then(response => response.data)
    .then(async ({ nftTokenId, buyerAddress }) => {
      if (!nftTokenId) {
        throw new Error('Unable to complete transaction. Please use another form of payment.');
      }

      return await getNFTInfo(nftTokenId, buyerAddress);
    });
}

export const getNftList = (walletAddress) => {
  return axios.get('/blockchain/nft/list-by-address', {
      params: {
        walletAddress,
      }
    })
    .then(response => response.data)
    .then(async (nftList) => await Promise.all(nftList.map(async ({ nftId, ownerAddress }) => {
      return await getNFTInfo(nftId, ownerAddress);
    })));
}
