import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet-async";

import Countdown from 'react-countdown';
import countdownRenderer from '../../components/countdownRenderer';

import { Carousel, Card } from '../../components/Carousel/Carousel';

import './local.scss';

export default function Collection() {

  return (
    <>
      <Helmet>
        <title>UmbroID - Umbro’s First web3 Digital Collectible</title>
        <meta name="description" content="Become a DNIZN and get your hands on Umbro’s first ever digital collectible - the UmbroID Collection." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="UmbroID - Umbro’s First web3 Digital Collectible" />
        <meta
          property="og:description"
          content="Become a DNIZN and get your hands on Umbro’s first ever digital collectible - the UmbroID Collection."
        />
        <meta property="og:url" content="https://dnizn.com/collection" />
        <meta property="og:image" content="https://dnizn.com/og.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dnizn.com" />
        <meta property="twitter:url" content="https://dnizn.com/collection" />
        <meta name="twitter:title" content="UmbroID - Umbro’s First web3 Digital Collectible" />
        <meta name="twitter:description" content="Become a DNIZN and get your hands on Umbro’s first ever digital collectible - the UmbroID Collection." />
        <meta name="twitter:image" content="https://dnizn.com/og.png" />
      </Helmet>
      <section className="common--umbro-nations">
        <Carousel>
          <Card src={require('../../i/nft-stills/england.jpg')} />
          <Card src={require('../../i/nft-stills/england_away.jpg')} />
          <Card src={require('../../i/nft-stills/brazil.jpg')} />
          <Card src={require('../../i/nft-stills/germany.jpg')} />
          <Card src={require('../../i/nft-stills/france.jpg')} />
          <Card src={require('../../i/nft-stills/spain.jpg')} />
          <Card src={require('../../i/nft-stills/usa.jpg')} />
          <Card src={require('../../i/nft-stills/mexico.jpg')} />
        </Carousel>
        <div className="text">
          <h2>UmbroID Limited Collection</h2>
          <p>To commemorate Umbro’s iconic role in the beautiful game throughout the generations, and to celebrate the biggest festival in world football (the first to be staged in the Middle East), Umbro are launching their first ever 808 limited digital collectibles.</p>
          <p>You’ve collected stickers. You’ve collected playing cards. Badges. Pins. Shirts. Now get your hands on the first ever Umbro digital collectibles with the UmbroID Limited Collection, exclusively here, on DNIZN.</p>

          <Countdown
            date={Date.parse('2 Dec 2022 21:00:00 GMT')}
            renderer={countdownRenderer}
          />

        </div>
      </section>

      <section className="introducing">
        <h2>Introducing UMBRO’s first ever digital collectibles</h2>
        <p>808 limited, collectible, animated UmbroIDs will be landing on DNIZN, Q4 2022. Just in time for the start of the biggest tournament in world football. Featuring distinct and original patterning designs — a fusion of Umbro’s iconic, retro, international football kit patterns and colours. The collection celebrates the nations participating in the tournament — as well as its dramatic desert location. Crucially, each limited-edition collectible will retail at only £25.</p>
        <p>No crypto. No gas fees. No hidden transaction fees. No worries.</p>
        <p>The digital collectibles are purchased directly on DNIZN, using standard currencies and normal payment methods. No crypto involved. Just a Debit or Credit Card will get you an UmbroID Limited Digital Collectible. It’s that easy.</p>
        <p>Your digital collectible will also give you the chance to enter various draws throughout the tournament and win both physical as well as digital rewards and giveaways. </p>
        <p>Pre-registration for the UmbroID Limited Collection closes early November 2022, with the sale commencing later in the year.</p>
        
        <Countdown
          date={Date.parse('2 Dec 2022 21:00:00 GMT')}
          renderer={countdownRenderer}
        />

      </section>

      <section id="faq">
        <h2>FAQs</h2>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q1" />
          <label htmlFor="faq--q1">Where and how can I buy an UmbroID Digital Collectible?</label>
          <div>The UmbroID Limited Collection is exclusively available on the DNIZN website (<a href="https://www.dnizn.com/">www.dnizn.com</a>). A Google or Apple account and a debit or credit card is all you’ll need to buy your digital collectible. Easy.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q2" />
          <label htmlFor="faq--q2">When was the UmbroID Collection launched?</label>
          <div>The UmbroID collection went on sale on the 18th of November 2022, exclusively on <a href="https://www.dnizn.com/">www.dnizn.com</a>.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q3" />
          <label htmlFor="faq--q3">How many digital collectibles are there in the collection?</label>
          <div>There are 808 limited, collectible, animated UmbroIDs available in the UmbroID Collection. 800 (100 in each design) are available for purchase and 8 have been reserved to give away.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q4" />
          <label htmlFor="faq--q4">What can the UmbroIDs Collectibles be used for?</label>
          <div>The UmbroID Digital Collectibles are first and foremost the very first digital collectibles to celebrate Umbro’s heritage in the beautiful game over the last century. However, throughout the tournament, digital collectible owners also get the chance to enter various DNIZN draws to win both physical, digital, and experiential rewards. Join the DNIZN community on <a href="https://discord.gg/dpHftmYJE4" target="_blank">Discord</a> for up-to-date information on rewards, giveaways, and airdrops.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q5" />
          <label htmlFor="faq--q5">Can the UmbroID Collectibles be re-sold/traded?</label>
          <div>Not yet. Our Secondary Marketplace is not due to launch until Q1 2023. Stay informed by joining the DNIZN community on <a href="https://discord.gg/dpHftmYJE4" target="_blank">Discord</a>.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q6" />
          <label htmlFor="faq--q6">Do I need a crypto wallet and cryptocurrency to buy the digital collectibles?</label>
          <div>No. There’s no Crypto here. All you need to buy the digital collectibles on DNIZN is a Debit or Credit Card. That’s it. Quick and easy.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q7" />
          <label htmlFor="faq--q7">Where are my digital collectibles held?</label>
          <div>All digital collectibles purchased via <a href="https://www.dnizn.com/">www.dnizn.com</a> are kept on the DNIZN platform. They can’t be transferred out into other wallets or onto other marketplaces.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q8" />
          <label htmlFor="faq--q8">What blockchain is DNIZN on?</label>
          <div>DNIZN is built on Avalanche, a super-fast, eco-friendly blockchain. Find out more, <a href="https://www.avax.network/">here</a>.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--q9" />
          <label htmlFor="faq--q9">Can I transfer my Digital Collectible to another wallet?</label>
          <div>All digital collectibles purchased via <a href="https://www.dnizn.com/">www.dnizn.com</a> are kept on the DNIZN platform. They can’t be transferred out into other wallets or onto other marketplaces. By keeping your digital collectibles on the DNIZN platform, you will be able to benefit from platform rewards, something you would not be able to utilise somewhere else.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--qA" />
          <label htmlFor="faq--qA">Can I sell or trade my Digital Collectibles in other marketplaces?</label>
          <div>All Digital Collectibles purchased via <a href="https://www.dnizn.com/">www.dnizn.com</a> are kept on the DNIZN platform. They can’t be transferred out into other wallets or onto other marketplaces. However, from Q1 2023, our Secondary Marketplace will be launched within DNIZN allowing internal trades. Stay informed by joining the DNIZN community on <a href="https://discord.gg/dpHftmYJE4" target="_blank">Discord</a>.</div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--qB" />
          <label htmlFor="faq--qB">What are the UmbroID Digital Collectibles? </label>
          <div>
            <p>They are digital animated UmbroIDs, featuring distinct and original patterning designs — a fusion of Umbro’s iconic, retro, international football kit patterns and colours. The collection celebrates the nations participating in the tournament — as well as its dramatic desert location.</p>
            <p>The UmbroID digital collectibles are cryptographically unique tokens using the ERC271 standard to provide and ensure your proof of ownership once you buy them.</p>
          </div>
        </div>

        <div className="bayan-item">
          <input type="checkbox" id="faq--qC" />
          <label htmlFor="faq--qC">User account/wallet — how does it work? </label>
          <div>
            <p>In order to create a user account and a wallet on DNIZN, you will need to first sign-in with either your Google or AppleID. Once you have signed in, we will create a wallet for you on the Avalanche blockchain. You can then go ahead and purchase an UmbroID digital collectible using your debit or credit card.</p>
            <p>Once the payment goes through, we will transfer the digital collectible into your wallet and in essence your profile. You can view your UmbroID anytime you like through your profile page and share it on Twitter for your followers to see.</p>
            <p>For your own safety and to ensure the authenticity of the digital collectibles, the private keys linked to your account are encrypted within the platform.</p>
            <p>It is much easier for you to recover your account than if you had private keys.</p>
          </div>
        </div>

      </section>
    </>
  );

};
