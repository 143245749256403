import {useRef} from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Header() {
  
  const burgerState = useRef();

  const closeMenu = () => {
    burgerState.current.checked = false;
  }
  return (
    <header>
      <div className="layout-container">
        <div className="logo">
          <NavLink to="/">
            <svg viewBox="0 0 195 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M149.631 9.47452H159.316L173.844 32.737V9.47452H183.529V48.4549H173.844L159.316 25.1924V48.4549H149.662V9.47452H149.631Z" />
              <path d="M110.575 39.8729L130.166 18.0565H111.896V9.47452H142.713V18.0565L123.122 39.8729H143.562V48.4549H110.575V39.8729Z" />
              <path d="M94.5066 9.47452H104.475V48.4549H94.5066V9.47452Z" />
              <path d="M53.3759 9.47452H63.0296L77.5575 32.737V9.47452H87.2427V48.4549H77.5575L63.0296 25.1924V48.4549H53.3759V9.47452V9.47452Z" />
              <path d="M12.6854 9.47452H30.2005C40.5461 9.47452 47.4641 17.2706 47.4641 28.9647C47.4641 40.6588 40.5147 48.4549 30.1691 48.4549H12.6854V9.47452ZM30.1376 39.8729C34.54 39.8729 37.4644 35.5033 37.4644 28.9647C37.4644 22.4575 34.5086 18.0565 30.1376 18.0565H22.5907V39.8729H30.1376Z" />
              <path fillRule="evenodd" clipRule="evenodd" d="M0.99707 0.493164H194.997V56.4932H0.99707V0.493164ZM3.99697 3.49245V53.4939H191.997V3.49245H3.99697Z" />
            </svg>
          </NavLink>
        </div>

        <input type="checkbox" id="toggle-menu" ref={burgerState} /><label htmlFor="toggle-menu"></label>
        <ul className="menu">
          <li className="umbro"><NavLink to="/collection" onClick={closeMenu}>UMBRO collection</NavLink></li>
          <li><HashLink to="/collection/#faq" onClick={closeMenu}>FAQS</HashLink></li>
          <li className="collection"><NavLink to="/my-collection" onClick={closeMenu}>My Collection</NavLink></li>
        </ul>

      </div>
    </header>
  );
}
