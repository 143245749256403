import Axios from 'axios';

export const axiosAuth = (baseURL, token) => {
  return Axios.create({
    baseURL,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  });
}
