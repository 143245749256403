export default function CollectionDescription() {
  return (
    <section className="-collection-description">
      <div className="title">
        <h3>Collection Description</h3>
      </div>
      <p>808 limited, collectible, animated UmbroIDs will be landing on DNIZN, Q4 2022. Just in time for the start of the biggest tournament in world football. Featuring distinct and original patterning designs — a fusion of Umbro’s iconic, retro, international football kit patterns and colours. The collection celebrates the nations participating in the tournament — as well as its dramatic desert location. Crucially, each limited-edition collectible will retail at only £25.</p>
      
      <p>No crypto. No gas fees. No hidden transaction fees. No worries.</p>
      
      <p>The digital collectibles are purchased directly on DNIZN, using standard currencies and normal payment methods. No crypto involved. Just a Debit or Credit Card will get you an UmbroID Limited Digital Collectible. It’s that easy.</p>

      <p>Your digital collectible will also give you the chance to enter various draws throughout the tournament and win both physical as well as digital rewards and giveaways.</p>

      <p>Pre-registration for the UmbroID Limited Collection closes early November 2022, with the sale commencing later in the year.</p>

    </section>
  );
}
