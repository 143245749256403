import { API_REQUEST } from "../constants";
import {
    addSpecialRole as addSpecialRoleRequest,
} from '../../services/discordApi';
import { DISCORD_ADD_SPECIAL_ROLE } from "./constants";

export const addSpecialRole = (data) => {
    return {
        type: DISCORD_ADD_SPECIAL_ROLE + API_REQUEST,
        prefix: DISCORD_ADD_SPECIAL_ROLE,
        filter: API_REQUEST,
        request: () => addSpecialRoleRequest(data),
    };
}
