import { getToken } from '../../services/storage';
import { USER_CHECK_WALLET, USER_FETCH } from '../user/constants';
import { TOKEN_ID_FIRST, TOKEN_ID_SECOND } from '../../pages/oauth2/constants';
import { allocWallet } from '../user/actions';
import { API_SUCCESS } from '../constants';
import { authCheck } from '../auth/actions';

const auth = ({ dispatch }) => next => action => {
    next(action);

    const { type, payload } = action;

    if (type === USER_FETCH + API_SUCCESS && !payload.data?.address) {
        const firstToken = getToken(TOKEN_ID_FIRST);
        const secondToken = getToken(TOKEN_ID_SECOND);

        dispatch(allocWallet(firstToken, secondToken));
    }

    if (type === USER_CHECK_WALLET) {
        dispatch(authCheck());
    }
};

export default auth;
