import Axios from 'axios';
import { tokenFirstUrl, tokenSecondUrl } from '../helpers/authUrl';
import { axiosAuth } from '../helpers/axios';
import { getToken } from './storage';
import { TOKEN_ID_FIRST } from '../pages/oauth2/constants';

const axios = Axios.create();

const getOauthToken = (url, code, clientId, redirectUrl, clientSecret) => {
  const params = new URLSearchParams();

  params.append('grant_type', 'authorization_code');
  params.append('client_id', clientId ? clientId : '');
  params.append('client_secret', clientSecret ? clientSecret : '');
  params.append('code', code);
  params.append('redirect_uri', redirectUrl);

  return axios.post(url,
    params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }
  );
}

export const getUser = () => {
  return axiosAuth(process.env.REACT_APP_FUSION_URL_FIRST, getToken(TOKEN_ID_FIRST))
    .get(`/api/user`)
    .then((response) => response.data?.user);
}

export const updateUser = (data) => {
  return axiosAuth(process.env.REACT_APP_USER_API_URL, getToken(TOKEN_ID_FIRST))
    .patch(`/user`, data)
    .then((response) => response.data?.user);
}

export const updateUserData = (data) => {
  return axiosAuth(process.env.REACT_APP_USER_API_URL, getToken(TOKEN_ID_FIRST))
    .patch(`/user`, data)
    .then((response) => response.data?.user);
}

export const getUserByWallet = (wallet) => {
  return axiosAuth(process.env.REACT_APP_USER_API_URL, getToken(TOKEN_ID_FIRST))
    .get(`/user/search?wallet=${wallet}`)
    .then((response) => response.data?.user);
}

export const getOauthTokenFirst = (code) => {
  const url = tokenFirstUrl();
  const {
    REACT_APP_FUSION_CLIENT_FIRST,
    REACT_APP_FUSION_REDIRECT_FIRST,
    REACT_APP_FUSION_AUTH_KEY_FIRST
  } = process.env;

  return getOauthToken(
    url,
    code,
    REACT_APP_FUSION_CLIENT_FIRST,
    REACT_APP_FUSION_REDIRECT_FIRST,
    REACT_APP_FUSION_AUTH_KEY_FIRST
  );
};

export const getOauthTokenSecond = (code) => {
  const url = tokenSecondUrl();
  const {
    REACT_APP_FUSION_CLIENT_SECOND,
    REACT_APP_FUSION_REDIRECT_SECOND,
    REACT_APP_FUSION_AUTH_KEY_SECOND
  } = process.env;

  return getOauthToken(
    url,
    code,
    REACT_APP_FUSION_CLIENT_SECOND,
    REACT_APP_FUSION_REDIRECT_SECOND,
    REACT_APP_FUSION_AUTH_KEY_SECOND
  );
};
