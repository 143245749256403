export const authFirstUrl = () => {
  return (
    `${process.env.REACT_APP_FUSION_URL_FIRST}/oauth2/authorize` +
    `?client_id=${process.env.REACT_APP_FUSION_CLIENT_FIRST}&` +
    `response_type=code&redirect_uri=${process.env.REACT_APP_FUSION_REDIRECT_FIRST}`
  );
}

export const tokenFirstUrl = () => {
  return `${process.env.REACT_APP_FUSION_URL_FIRST}/oauth2/token`;
}

export const authSecondUrl = () => {
  return (
    `${process.env.REACT_APP_FUSION_URL_SECOND}/oauth2/authorize` +
    `?client_id=${process.env.REACT_APP_FUSION_CLIENT_SECOND}&` +
    `response_type=code&redirect_uri=${process.env.REACT_APP_FUSION_REDIRECT_SECOND}`
  );
}

export const tokenSecondUrl = () => {
  return `${process.env.REACT_APP_FUSION_URL_SECOND}/oauth2/token`;
}
