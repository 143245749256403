import {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { getCookieWarningClosed, setCookieWarningClosed } from '../services/storage';

export default function CookiesWarning() {
  const [cookiesWarningShow, setCookiesWarningShow] = useState(!getCookieWarningClosed());

  const onCloseCookiesWarningClick = () => {
    setCookiesWarningShow(false);
    setCookieWarningClosed(true);
  }

  return (
    <div className="cookies-warning" style={{display: cookiesWarningShow ? 'block' : 'none' }}>
      <p>This website uses cookies. By continuing to browse this website you are agreeing to our use of cookies. Review our Cookie Policy for more information. <NavLink to="/cookies" >Learn More</NavLink></p>
      <button className="-wide" onClick={onCloseCookiesWarningClick}>OK</button>
    </div>
  );
}
