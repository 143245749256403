import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet-async";

export default function Privacy() {

  return (
    <>
      <Helmet>
        <title>DNIZN - Privacy Policy</title>
        <meta name="description" content="DNIZN - Privacy Policy" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DNIZN - Privacy Policy" />
        <meta property="og:url" content="https://dnizn.com/privacy" />
        <meta property="og:image" content="https://dnizn.com/og.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dnizn.com" />
        <meta property="twitter:url" content="https://dnizn.com/privacy" />
        <meta name="twitter:title" content="DNIZN - Privacy Policy" />
        <meta name="twitter:image" content="https://dnizn.com/og.png" />
      </Helmet>
      <section className="legal">
        <h1>DNIZN PRIVACY POLICY</h1>
        <ol>
          <li>
            <h2>Introduction</h2>
            <ol>
              <li>This privacy policy (<strong>“Privacy Policy”</strong>) is part of the <Link to="/terms">DNIZN Service Terms</Link> (<strong>“DNIZN Service Terms”</strong>) for the use of DNIZN operated by Equitbl Limited, (<strong>“we”</strong>, <strong>“us”</strong>, <strong>“our”</strong>, <strong>“Equitbl”</strong>) and the supply of our Services to each customer (<strong>“you”</strong>, <strong>“your”</strong> and <strong>“Member”</strong>). Capitalised terms have the meanings in the DNIZN Service Terms, unless they are defined in this policy. Our details are stated in Clause 2 of the DNIZN Service Terms.</li>

              <li>We decide the purposes and means of processing of personal data that is collected about you when you deal with us. We are committed to protecting and respecting your privacy and we have legal obligations to do so as the <strong>‘controller’</strong> of your personal data under the Data Protection Act 2018 (the <strong>“Act”</strong>). </li>

              <li>This Privacy Policy and the DNIZN Service Terms explain what types of personal data is collected, the purposes for which it is collected and processed, the legal basis for that and the organisations or types of organisations, if any, to which we may provide your personal data. It is necessary for you to agree to the DNIZN Service Terms, including the Privacy Policy, and for us to collect, process, share and store the personal data as described in order for us to be able to transact and communicate with you, and interact with you via the Services.</li>

              <li>The Services may from time to time contain links to and from the websites of partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and we do not accept any responsibility or liability for those policies. Please check those policies and any relevant service terms and conditions before you submit any personal data to those websites. We are not responsible for the content of any other websites or services.</li>
            </ol>
          </li>



          <li>
            <h2>What personal data do we collect?</h2>
            <ol>
              <li>
                We may collect, store and use the personal data described in the table in Annex A to this Privacy Policy, and process it for the purposes and on the legal basis specified in that table. We rely on the following lawful bases for processing, where specified in the table in Annex A:
                <ol className="-a">
                  <li><strong>Performance of a Contract</strong> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
                  <li><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal obligation to which we are subject.</li>
                  <li><strong>Legitimate Interest</strong> means the processing is necessary for the purposes of the legitimate interests pursued by us or by a third party, except where such interests are overridden by your interests or fundamental rights and freedoms which require protection of personal data. You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us at <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a>.</li>
                  <li><strong>Consent</strong> means processing your personal data where you have signified your agreement by a statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous indication of what you want. You can withdraw your consent at any time by contacting us at <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a>.</li>
                </ol>
              </li>

              <li>Personal data will be stored in accordance with Clause 5 of this Privacy Policy.</li>

              <li>We aim to keep your personal information up-to-date, so you must promptly tell us by contacting us at <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a> if you change your name, residential address or contact details.</li>
            </ol>
          </li>



          <li>
            <h2>When may we disclose your personal data?</h2>
            <ol>
              <li>We will not disclose your personal data to any third party, except as explained in the table in Annex A to this Privacy Policy.</li>
              <li>If some or all of our business is bought by a third party, personal data held by us will be transferred and may be disclosed to the prospective seller or buyer of such business under the same conditions as this Privacy Policy. This is necessary for the purposes of the legitimate interests pursued by us and the third party, and would not be overridden by your interests or fundamental rights and freedoms which require protection of personal data.</li>
              <li>As a Seller, you will not have access to personal data (including in aggregated form) that any Buyer or other Member provides for the use of the Services or which is generated by any of them through the provision of the Services.</li>
            </ol>
          </li>



          <li>
            <h2>How we use your personal data</h2>
            <ol>
              <li>
                <p>We will only collect, process, use, store and share your personal data where the Act allows us to do so. Most commonly we will use your personal data in the following circumstances:</p>
                <ul>
                  <li>Where we need to perform an obligation under a contract with you, such as the DNIZN Service Terms, or take steps at your request before agreeing a contract with you</li>
                  <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights that require the protection of personal data do not override those interests</li>
                  <li>Where we need to comply with a legal or regulatory obligation</li>
                  <li>Where you have consented before the processing</li>
                </ul>
              </li>

              <li>You can find out more about the types of lawful basis that we will rely on to process your personal data in the table in Annex A to this Privacy Policy.</li>
            </ol>
          </li>



          <li>
            <h2>Where and for how long we store your personal data</h2>
            <ol>
              <li>All information you provide to us is stored on our secure servers, located in the United Kingdom.</li>
              <li>We use all reasonable endeavours to ensure that appropriate technical and organisational measures are in place to protect your personal data from unauthorised or unlawful processing and against accidental loss, destruction or damage. </li>
              <li>Your personal data will be stored for as long as you use the Services and for such time afterwards as required by law or six years from when you stop using the Services (which is the legal limitation period).</li>
              <li>Please note that using the internet or mobile networks is not completely secure, so we cannot guarantee the security of your data transmitted between your device(s) and the Services. Any such transmission is at your own risk.</li>
            </ol>
          </li>



          <li>
            <h2>Your rights</h2>
            <ol>
              <li>Under the Act, you have a number of rights. The first right is to receive confirmation as to whether or not any of your personal data is being processed and certain other information, as we have set out in this Privacy Policy.</li>
              <li>
                The table in Annex B at the end of this Privacy Policy explains the following rights which the Act gives you in relation to your personal data, and any exceptions to those rights:
                <ol className="-a">
                  <li>Right of access</li>
                  <li>Right to rectification</li>
                  <li>Right to erasure</li>
                  <li>Right to request the restriction of processing concerning you</li>
                  <li>Right to data portability</li>
                  <li>Right to object to processing</li>
                  <li>Right to ask us not to process your personal data for direct marketing purposes</li>
                  <li>Right not to be subject to automated individual decision-making, including profiling.</li>
                </ol>
              </li>

              <li>Please note that you may exercise these rights (subject to any applicable exceptions) by contacting us at <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a>.</li>
            </ol>
          </li>



          <li>
            <h2>Marketing and advertising</h2>
            <ol>
              <li>We seek to give you choices about what marketing and advertising you see on the Services. We will give you a choice to opt-in to receiving marketing and advertising specifically tailored for you. If you do not opt-in, we may still send you generalised marketing and advertising messages. </li>
              <li>We use personal data you have provided to us, and responses to our survey questions, for our loyalty programme and to work out if certain advertising and marketing will be of interest to you. We may use an automated process to create a profile for you. </li>
              <li>You can ask us to stop profiling you and sending you any targeted marketing or advertising messages on the Services at any time by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a>. Further information can be found in Table B.</li>
            </ol>
          </li>



          <li>
            <h2>Cookies</h2>
            <ol>
              <li>We use cookies to distinguish you from other users of the Services. This helps us to provide you with a good experience. By visiting and using the Services, you agree to our use of cookies.</li>
              <li>
                A cookie is a small file of letters and numbers that we store on your browser, computer, or device. Cookies send information back to the originating website on each subsequent visit, or to another website which recognises that cookie. We may use the following cookies:
                <ul>
                  <li>Strictly necessary cookies. These are cookies that are required for the operation of the Services. They include, for example, cookies that enable you to log into secure accounts, use interactive features and Services.</li>
                  <li>Analytical/performance cookies. These allow us to recognise and count the number of visitors and users and see how they use the Services. This helps us to improve the way our Services works, for example, by ensuring that users are finding what they are looking for easily.</li>
                  <li>Functionality cookies. These are used to recognise you when you return to our Services. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                  <li>Targeting cookies. These cookies record your visit to our Services, the pages you have visited and the links you have followed. We will use this information to make our Services and the information displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
                </ul>
              </li>

              <li>Please note that third parties (including, for example, providers of external services like web traffic analysis services) may also use cookies, over which we have no control. You should refer to their privacy policies or cookie policies for the relevant information about those cookies.</li>

              <li>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. All browsers provide tools that allow you to control how you handle cookies: accept, reject or delete them. These settings are normally accessed via the ‘settings’, 'preferences' or 'options' menu of the browser you are using, but you could also look for a ‘help’ function or contact the browser provider. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of the Services.</li>
            </ol>
          </li>



          <li>
            <h2>Changes to our privacy policy</h2>
            <p>We may amend this Privacy Policy. Any changes we may make to our Privacy Policy in the future will be posted on this page and in accordance with Clause 17 of the DNIZN Service Terms.</p>
          </li>



          <li>
            <h2>Regulatory and Contact details</h2>
            <p>Our full company and registration details are set out in Clause 2 of the DNIZN Service Terms. </p>
            <p>Questions, comments and requests regarding this Privacy Policy are welcomed at <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a>.</p>
            <p>For more information about data protection and the protection of personal data, please visit the Information Commissioner’s website at <a href="https://www.ico.org.uk/" target="_blank">www.ico.org.uk</a>.</p>
          </li>



          <li>
            <h2>Complaints</h2>
            <p>You can complain to the Information Commissioner’s Office if you consider there has been a breach of the Act in connection with your personal data.</p>
          </li>

          <li>
            <h2>General</h2>
            <p>This Privacy Policy is governed by English law and either of us can bring legal proceedings in the courts of England and Wales in relation to any dispute arising out of or in connection with these DNIZN Service Terms, except that you may bring proceedings in Northern Ireland or Scotland if you are resident there.</p>
          </li>
        </ol>
      </section>



      <section className="legal">
        <h2>Annex A</h2>
        <table>
          <thead>
            <tr>
              <th>Personal Data Collected</th>
              <th>Purpose</th>
              <th>Lawful Basis for Processing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>If you decide to register as a user of the Services, you may be asked to complete a form with your name, email address and telephone number.</p>
                <p>We may monitor, record, store and use any telephone, email address or other communication.</p>
              </td>
              <td>
                <ul>
                  <li>to manage and administer the Services;</li>
                  <li>to enable you to use the Services;</li>
                  <li>to check any order or other instructions given to us;</li>
                  <li>for training purposes;</li>
                  <li>to seek payment for your order;</li>
                  <li>to improve the quality of our customer service;</li>
                  <li>to deal with enquiries, complaints and feedback from you;</li>
                  <li>to send you contractual notices;</li>
                  <li>to keep you informed about your activity relating to the Services;</li>
                </ul>
                <p>To disclose to third parties for:</p>
                <ul>
                  <li>To comply with a current judicial proceeding, a court order or legal process served on us or our Services, any request by any regulator who may have jurisdiction over us or for audit purposes and to meet obligations to any relevant regulatory authority or taxing authority;</li>
                  <li>To enforce this Privacy Policy or the DNIZN Service Terms;</li>
                  <li>to agents and subcontractors, acting for us, to use for the purpose of operating the Services;</li>
                  <li>to assist any type of investigation into your actions on DNIZN;</li>
                  <li>where Equitbl Limited is involved in a merger, sale, restructure, acquisition, joint venture, assignment or transfer (of business, shares, assets or otherwise);</li>
                  <li>To protect and defend our rights or safety, or that of Sellers or other Members;</li>
                  <li>To protect against abuse, misuse or unauthorized use </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>performance of a contract </li>
                  <li>compliance with a legal obligation </li>
                  <li>legitimate interests</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <p>Information about your device, mobile device or other item of hardware through which you access the Services and your visits to and use of the Services (including your device, operating system, length of visit, page views that you use)</p>
              </td>
              <td>
                <ul>
                  <li>to analyse and improve the features offered on the Services.</li>
                  <li>To ensure that content on the Services is presented in the most effective manner for you and for your device;</li>
                </ul>
                <p>To disclose to third parties for:</p>
                <ul>
                  <li>To comply with a current judicial proceeding, a court order or legal process served on us or our Services, any request by any regulator who may have jurisdiction over us or for audit purposes and to meet obligations to any relevant regulatory authority or taxing authority;</li>
                  <li>To enforce this Privacy Policy or the DNIZN Service Terms.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>performance of a contract </li>
                  <li>legitimate interests </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <p>Information relating to your time taken to respond to questions, links you have clicked; and length of time you have spent on the Services</p>
              </td>
              <td>
                <ul>
                  <li>to deal with enquiries, complaints and feedback from you;</li>
                  <li>to analyse and improve the features offered on the Services.</li>
                </ul>
                <p>To disclose to third parties for:</p>
                <ul>
                  <li>To comply with a current judicial proceeding, a court order or legal process served on us or our Services, any request by any regulator who may have jurisdiction over us or for audit purposes and to meet obligations to any relevant regulatory authority or taxing authority;</li>
                  <li>To enforce this Privacy Policy or the DNIZN Service Terms.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>performance of a contract </li>
                  <li>compliance with a legal obligation </li>
                  <li>legitimate interests</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <p>Correspondence between you and us</p>
              </td>
              <td>
                <ul>
                  <li>to manage and administer the Services;</li>
                  <li>to enable you to use the Services;</li>
                  <li>to deal with enquiries, complaints and feedback from you;</li>
                </ul>
                <p>To disclose to third parties for:</p>
                <ul>
                  <li>To comply with a current judicial proceeding, a court order or legal process served on us or our Services, any request by any regulator who may have jurisdiction over us or for audit purposes and to meet obligations to any relevant regulatory authority or taxing authority;</li>
                  <li>To enforce this Privacy Policy or the DNIZN Service Terms.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>performance of a contract </li>
                  <li>compliance with a legal obligation </li>
                  <li>legitimate interests </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <p>Details of your answers to Questions via the Services</p>
              </td>
              <td>
                <ul>
                  <li>to conduct surveys</li>
                  <li>to manage and administer the Services;</li>
                  <li>to enable you to use the Services;</li>
                  <li>for statistical analysis;</li>
                  <li>to develop and improve the Services;</li>
                </ul>
                <p>To disclose to third parties for:</p>
                <ul>
                  <li>To comply with a current judicial proceeding, a court order or legal process served on us or our Services, any request by any regulator who may have jurisdiction over us or for audit purposes and to meet obligations to any relevant regulatory authority or taxing authority;</li>
                  <li>To enforce this Privacy Policy or the DNIZN Service Terms;</li>
                  <li>to agents and subcontractors, acting for us, to use for the purpose of operating the Services.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>performance of a contract </li>
                  <li>compliance with a legal obligation </li>
                  <li>legitimate interests </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <p>Records of any telephone, email or other communication with you</p>
              </td>
              <td>
                <p>In addition to the purposes specified in Row 1 above:</p>
                <ul>
                  <li>to improve the quality of our customer service.</li>
                </ul>
                <p>To disclose to third parties for:</p>
                <ul>
                  <li>To comply with a current judicial proceeding, a court order or legal process served on us or our Services, any request by any regulator who may have jurisdiction over us or for audit purposes and to meet obligations to any relevant regulatory authority or taxing authority;</li>
                  <li>To enforce this Privacy Policy or the DNIZN Service Terms.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>performance of a contract </li>
                  <li>compliance with a legal obligation </li>
                  <li>legitimate interests </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td><p>Name, email address</p></td>
              <td>
                <ul><li>Direct marketing (e.g. mailing lists for newsletters).</li></ul>
              </td>
              <td>
                <ul><li>Consent</li></ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>



      <section className="legal">
        <h2>Annex B<br />See Clause 6.3 to exercise your rights.</h2>
        <table>
          <thead>
            <tr>
              <th>Rights</th>
              <th>Exception</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><strong>Right of Access:</strong> To obtain from us confirmation as to whether or not personal data concerning you are being processed, and, where that is the case, access to the personal data and the following information: (a) the purposes of the processing; (b) the categories of personal data concerned; (c) the recipients or categories of recipient to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organisations; (d) where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period; (e) the existence of the right to request from the controller rectification or erasure of personal data or restriction of processing of personal data concerning the data subject or to object to such processing; (f) the right to lodge a complaint with a supervisory authority; (g) where the personal data are not collected from the data subject, any available information as to their source; (h) the existence of automated decision-making, including profiling and (4) and, at least in those cases, meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for the data subject.</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p><strong>Right to rectification:</strong> to obtain from us without undue delay the rectification of inaccurate personal data concerning you.</p>
                <p>We must communicate to each recipient to whom the rectified personal data have been disclosed, unless this proves impossible or involves disproportionate effort. </p>
                <p>We shall inform you about those recipients if you request that information.</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p><strong>Right to erasure:</strong> to obtain from us the erasure of personal data concerning you without undue delay where: (a) the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; (c) you object to the processing based on legitimate interest where there are no overriding legitimate grounds for the processing; (d) the personal data have been unlawfully processed; (e) the personal data have to be erased for compliance with a legal obligation to which we are subject.</p>
                <p>We must communication to each recipient to whom the erased personal data have been disclosed, unless this proves impossible or involves disproportionate effort. </p>
                <p>We shall inform you about those recipients.</p>
              </td>
              <td>
                <p>Processing is necessary for (b) compliance with a legal obligation which requires processing by law to which the controller is subject or for the performance of a task carried out in the public interest or in the exercise of official authority vested in us; or (e) the establishment, exercise or defence of legal claims.</p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>Right to request the restriction of processing concerning you:</strong> to obtain from us restriction of processing where: </p>
                <p>(a) the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of the personal data; </p>
                <p>(b) the processing is unlawful and you oppose the erasure of the personal data and request the restriction of its use instead; </p>
                <p>(c) we no longer need the personal data for the purposes of the processing, but it is required by you for the establishment, exercise or defence of legal claims; </p>
                <p>(d) you object to the processing based on legitimate interest pending the verification whether our legitimate grounds override yours. </p>
                <p>We must communication to each recipient to whom the restricted personal data have been disclosed, unless this proves impossible or involves disproportionate effort. </p>
                <p>We shall inform you about those recipients if you request that information.</p>
              </td>
              <td>
                <p>Where processing has been restricted under this right, such personal data shall, with the exception of storage, only be processed:</p>
                <ol className="-a">
                  <li>with your consent; or </li>
                  <li>for the establishment, exercise or defence of legal claims; or</li> 
                  <li>for the protection of the rights of another natural or legal person; or </li>
                  <li>for reasons of important public interest of the UK.</li>
                </ol>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>The right to data portability:</strong> to receive the personal data concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller without hindrance from us, where: </p>
                <p>(a) the processing is based on consent or is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract; and </p>
                <p>(b) the processing is carried out by automated means. </p>
                <p>You have the right to have the personal data transmitted directly from us to another controller, where technically feasible. </p>
                <p>The exercise of this right shall be without prejudice to the right to erasure. </p>
              </td>
              <td>
                <p>That right shall not apply to processing necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in us.</p>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>The right to object to processing:</strong> to object, on grounds relating to your particular situation, at any time to processing of personal data concerning you which is based on processing necessary for the purposes of the legitimate interests pursued by us or a third party (except where such interests are overridden by your interests or fundamental rights and freedoms which require protection of personal data), including profiling.</p>
              </td>
              <td>
                <p>Where: </p>
                <ol className="-a">
                  <li>we demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject; or</li> 
                  <li>for the establishment, exercise or defence of legal claims.</li>
                </ol>
              </td>
            </tr>

            <tr>
              <td>
                <p><strong>The right to ask us not to process your personal data for direct marketing purposes:</strong> to object at any time to processing of personal data concerning you for such marketing, which includes profiling to the extent that it is related to such direct marketing.</p>
                <p>You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us through <a href="mailto:privacy@equitbl.com">privacy@equitbl.com</a>, as explained in paragraph 6.3 of the Privacy Policy.</p>
              </td>
              <td />
            </tr>

            <tr>
              <td>
                <p><strong>The right not to be subject to automated individual decision-making, including profiling:</strong> to not be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you. </p>
              </td>
              <td>
                <p>If the decision: </p>
                <ol className="-a">
                  <li>is necessary for entering into, or performance of, a contract between you and us;</li> 
                  <li>is authorised by law to which we are subject and which also lays down suitable measures to safeguard the data subject's rights and freedoms and legitimate interests; or </li>
                  <li>is based on the data subject's explicit consent.</li>
                </ol>
                <p>In the cases referred to in points (a) and (c) we shall implement suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, at least the right to obtain human intervention on our part, to express his or her point of view and to contest the decision.</p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  )

};