import { Link } from 'react-router-dom';

export default function countdownRenderer({days, hours, minutes, seconds, completed}) {

  const leadingZeroes = (number) => {
    return(String(number).padStart(2, '0'));
  }

  if (completed) {
    return(
        <button type="button" disabled={true} className="-wide -disabled">Sale Ended</button>
    )
  } else {
    return(
      <div className="countdown-block">
        <div className="countdown">
          <h3>Sale closes in:</h3>
          <div>
            <div><h4>{leadingZeroes(days)}</h4><span>days</span></div>
            <div><h4>{leadingZeroes(hours)}</h4><span>hours</span></div>
            <div><h4>{leadingZeroes(minutes)}</h4><span>minutes</span></div>
            <div><h4>{leadingZeroes(seconds)}</h4><span>seconds</span></div>
          </div>
        </div>

        <Link to="/nft/buy"><button className="-gradient -wide">Get Yours Now</button></Link>
        
      </div>
    )
    }
}