import { USER_ALLOC_WALLET, USER_FETCH, USER_RESET_UPDATED, USER_UPDATE, USER_UPDATE_DATA } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    info: null,
    wallet: null,
    isWalletRequested: false,
    error: null,
    isFetching: false,
    isUpdated: false,
};

export const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case USER_FETCH + API_REQUEST:
        case USER_ALLOC_WALLET + API_REQUEST:
            return { ...state, isFetching: true };
        case USER_FETCH + API_SUCCESS:
            return {
                ...state,
                isWalletRequested: true,
                info: payload || null,
                wallet: payload.data?.address || null,
                isFetching: false,
            };
        case USER_ALLOC_WALLET + API_SUCCESS:
            return {
                ...state,
                isWalletRequested: true,
                wallet: payload.data?.address || null,
                isFetching: false,
            };
        case USER_FETCH + API_ERROR:
        case USER_ALLOC_WALLET + API_ERROR:
            return {
                ...state,
                isWalletRequested: true,
                info: null,
                wallet: null,
                isFetching: false,
                error: payload,
            };
        case USER_UPDATE + API_REQUEST:
        case USER_UPDATE_DATA + API_REQUEST:
            return { ...state, isFetching: true, isUpdated: false };
        case USER_UPDATE + API_SUCCESS:
        case USER_UPDATE_DATA + API_SUCCESS:
            return {
                ...state,
                info: payload || null,
                isFetching: false,
                isUpdated: true,
            };
        case USER_UPDATE + API_ERROR:
        case USER_UPDATE_DATA + API_ERROR:
            return {
                ...state,
                isFetching: false,
                isUpdated: false,
                error: payload,
            };
        case USER_RESET_UPDATED:
            return { ...state, isUpdated: false, error: null };
        default:
            return state;
    }
};
