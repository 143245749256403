import { API_REQUEST } from "../constants";
import {
    buyNft as buyNftRequest,
    getNftList as getNftListRequest,
    getNftCountInStock as getNftCountInStockRequest,
} from '../../services/api';
import { NFT_BUY, NFT_OWNER, NFT_LIST, NFT_GET, NFT_CLEAR, NFT_COUNT_IN_STOCK } from "./constants";
import { getNFTInfo } from '../../services/blockchain';
import { getUserByWallet } from '../../services/authApi';

export const buyNft = (wallet, paymentMethodId, amount, currency) => {
    return {
        type: NFT_BUY + API_REQUEST,
        prefix: NFT_BUY,
        filter: API_REQUEST,
        request: () => buyNftRequest(wallet, paymentMethodId, amount, currency),
    };
}

export const getNft = (id) => {
    return {
        type: NFT_GET + API_REQUEST,
        prefix: NFT_GET,
        filter: API_REQUEST,
        request: () => getNFTInfo(id),
    };
}

export const getNftCountInStock = () => {
    return {
        type: NFT_COUNT_IN_STOCK + API_REQUEST,
        prefix: NFT_COUNT_IN_STOCK,
        filter: API_REQUEST,
        request: () => getNftCountInStockRequest(),
    };
}

export const getNftOwner = (wallet) => {
    return {
        type: NFT_OWNER + API_REQUEST,
        prefix: NFT_OWNER,
        filter: API_REQUEST,
        request: () => getUserByWallet(wallet),
    };
}

export const getNftList = (wallet) => {
    return {
        type: NFT_LIST + API_REQUEST,
        prefix: NFT_LIST,
        filter: API_REQUEST,
        request: () => getNftListRequest(wallet),
    };
}

export const nftClear = () => {
    return {
        type: NFT_CLEAR,
    };
}
