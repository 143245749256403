import { apiResponse } from '../actions';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';
import { AUTH_OAUTH_TOKEN_FIRST, AUTH_OAUTH_TOKEN_SECOND } from '../auth/constants';
import { logout } from '../auth/actions';

export default ({dispatch}) => (next) => (action) => {
  next(action);

  if (!action.filter || action.filter !== API_REQUEST) {
    return;
  }

  const { request, prefix } = action;

  return request()
    .then((data) => {
      dispatch(apiResponse({
        prefix,
        filter: API_SUCCESS,
        payload: data,
      }));
    })
    .catch((error) => {
      const isOauthTokenRequest = action.type === AUTH_OAUTH_TOKEN_FIRST + API_REQUEST ||
        action.type === AUTH_OAUTH_TOKEN_SECOND + API_REQUEST;

      if (error.response && error.response.status === 401 && !isOauthTokenRequest) {
        dispatch(logout());
      }

      dispatch(apiResponse({
        prefix: prefix,
        filter: API_ERROR,
        payload: error,
      }));
    });
};
