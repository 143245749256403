import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Subscribe from '../components/Subscribe';


const IconDiscord = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M20.317 4.493c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 0 0-.079.036c-.21.369-.444.85-.607 1.23a18.566 18.566 0 0 0-5.488 0 12.358 12.358 0 0 0-.617-1.23.077.077 0 0 0-.079-.038c-1.714.29-3.354.8-4.885 1.491a.07.07 0 0 0-.032.027C.533 9.094-.32 13.556.1 17.962a.08.08 0 0 0 .031.055 20.031 20.031 0 0 0 5.993 2.98.078.078 0 0 0 .084-.026 13.83 13.83 0 0 0 1.226-1.963.074.074 0 0 0-.04-.104 13.19 13.19 0 0 1-1.873-.878.074.074 0 0 1-.037-.06.075.075 0 0 1 .03-.065c.125-.093.251-.19.371-.287a.075.075 0 0 1 .078-.01c3.927 1.764 8.18 1.764 12.061 0a.075.075 0 0 1 .08.009c.12.098.244.195.371.288a.076.076 0 0 1 .031.064.076.076 0 0 1-.037.06c-.598.345-1.22.636-1.872.878a.074.074 0 0 0-.045.043.074.074 0 0 0 .004.062c.36.687.771 1.34 1.224 1.962a.076.076 0 0 0 .084.028 19.964 19.964 0 0 0 6.003-2.981.077.077 0 0 0 .032-.054c.5-5.094-.838-9.52-3.55-13.442a.06.06 0 0 0-.03-.028ZM8.02 15.279c-1.182 0-2.157-1.07-2.157-2.38 0-1.312.956-2.38 2.157-2.38 1.21 0 2.177 1.077 2.158 2.38 0 1.312-.957 2.38-2.158 2.38Zm7.976 0c-1.184 0-2.158-1.07-2.158-2.38 0-1.312.956-2.38 2.158-2.38 1.21 0 2.176 1.077 2.156 2.38 0 1.312-.946 2.38-2.157 2.38Z" fill="#fff"/></g><defs><clipPath id="a"><path d="M0 0h24v24H0z"/></clipPath></defs></svg>
  )
}

const IconTwitter = () => {
  return (
    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6435 4.93561C22.8085 5.30561 21.9115 5.55561 20.9685 5.66861C21.9415 5.0864 22.6694 4.17008 23.0165 3.09061C22.1023 3.6336 21.1018 4.01581 20.0585 4.22061C19.3569 3.47148 18.4276 2.97494 17.4148 2.80809C16.4021 2.64123 15.3626 2.8134 14.4577 3.29786C13.5529 3.78233 12.8333 4.55197 12.4107 5.48732C11.988 6.42266 11.886 7.47136 12.1205 8.47061C10.2682 8.37761 8.45613 7.89616 6.80192 7.05752C5.14772 6.21887 3.68834 5.04177 2.5185 3.60261C2.1185 4.29261 1.8885 5.09261 1.8885 5.94461C1.88805 6.71161 2.07693 7.46685 2.43838 8.14334C2.79982 8.81983 3.32266 9.39665 3.9605 9.82261C3.22078 9.79907 2.49737 9.5992 1.8505 9.23961V9.29961C1.85043 10.3754 2.22253 11.418 2.90368 12.2506C3.58483 13.0832 4.53307 13.6545 5.5875 13.8676C4.90128 14.0533 4.18184 14.0807 3.4835 13.9476C3.781 14.8732 4.3605 15.6826 5.14087 16.2625C5.92124 16.8424 6.86342 17.1638 7.8355 17.1816C6.18533 18.477 4.14739 19.1797 2.0495 19.1766C1.67788 19.1767 1.30658 19.155 0.9375 19.1116C3.06698 20.4808 5.54584 21.2074 8.0775 21.2046C16.6475 21.2046 21.3325 14.1066 21.3325 7.95061C21.3325 7.75061 21.3275 7.54861 21.3185 7.34861C22.2298 6.68959 23.0164 5.87351 23.6415 4.93861L23.6435 4.93561Z" />
    </svg>
  )
}

const IconMedium = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 1043.63 592.71" className="icon -medium">
      <path d="M588.67 296.36c0 163.67-131.78 296.35-294.33 296.35S0 460 0
      296.36 131.78 0 294.34 0s294.33 132.69 294.33 296.36M911.56 296.36c0
      154.06-65.89 279-147.17 279s-147.17-124.94-147.17-279 65.88-279
      147.16-279 147.17 124.9 147.17 279M1043.63 296.36c0 138-23.17 249.94-51.76
      249.94s-51.75-111.91-51.75-249.94 23.17-249.94 51.75-249.94 51.76 111.9
      51.76 249.94"></path>
    </svg>
  );
}

const IconInstagram = () => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 169.063 169.063"><path d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z"/><path d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zM129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 0 0-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"/></svg>
  )
}

export default function Footer() {
  return (
    <footer>
      <div className="layout-container">
        <div className="updates">
          <Subscribe />
        </div>
        <ul className="menu">
          <li><NavLink to="/about">About DNIZN</NavLink></li>
          <li><NavLink to="/collection">The UMBRO Collection</NavLink></li>
          <li><a href="https://discord.gg/dpHftmYJE4" target="_blank">Join Our Discord</a></li>
          <li><HashLink to="/collection/#faq">FAQS</HashLink></li>
        </ul>
      </div>

      <div>
        <div className="layout-container">
          <div className="copyright">Copyright © 2023 EQUITBL Ltd. All rights reserved.</div>
          <ul className="sublinks">
            <li><NavLink to="/terms">Service Terms</NavLink></li>
            <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
            <li><NavLink to="/cookies">Cookies Policy</NavLink></li>
          </ul>
          <ul className="social">
            <li>
              <a
                href="https://twitter.com/_DNIZN_"
                target="_blank"
                title="Twitter">
                <IconTwitter />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/_dnizn_/"
                target="_blank"
                title="Instagram">
                <IconInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/dnizn"
                target="_blank"
                title="Medium">
                <IconMedium />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/dpHftmYJE4"
                target="_blank"
                title="Discord">
                <IconDiscord />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
