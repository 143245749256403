import { useEffect, useRef, useState } from 'react';
import  { connect } from 'react-redux';
import { payByCard, paymentClear } from '../../store/payment/actions';
import { buyNft, nftClear, getNftOwner, getNftCountInStock } from '../../store/nft/actions';
import { updateUserData, resetUpdated } from '../../store/user/actions';
import { getRates, currencyClear } from '../../store/currency/actions';
import StripePaymentForm from '../../components/payment/StripePaymentForm';
import Video from '../../components/Video/Video';
import CollectionBlock from '../../layout/CollectionBlock';
import OwnerBlock from '../../layout/OwnerBlock';
import CollectionDescription from '../../layout/CollectionDescription';
import { getDefaultCurrency, calculatePrice } from '../../helpers/currency';
import { getNftPrice } from '../../helpers/nft';
import PriceSelector from '../../components/Nft/PriceSelector';
import { Carousel, Card } from '../../components/Carousel/Carousel';
import { getErrorMessage } from '../../helpers/error';

import './local.scss';

const BuyNft = ({
  wallet,
  user,
  userError,
  isUserFetching,
  paymentMethod,
  paymentError,
  isPaymentFetching,
  payByCard,
  paymentClear,
  buyNft,
  nftClear,
  getNftOwner,
  getNftCountInStock,
  nftOwner,
  nftBought,
  nftCountInStock,
  isNftFetching,
  nftError,
  resetUpdated,
  updateUserData,
  currencyRates,
  isCurrencyFetching,
  currencyError,
  getRates,
  currencyClear
}) => {
  let message;
  const defaultCurrency = getDefaultCurrency();
  const defaultNftPrice = getNftPrice();
  const playerRef = useRef(null);
  const nftPlayerRef = useRef(null);
  const [isRevealVideoPlaying, setRevealVideoPlaying] = useState(false);
  const [isRevealVideoEnded, setRevealVideoEnded] = useState(false);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [nftPrice, setNftPrice] = useState(defaultNftPrice);
  const pageRef = useRef(null);
  const messageRef = useRef(null);
  const isError = paymentError || nftError || currencyError || userError;
  const isFormDisabled = isPaymentFetching || isNftFetching || isCurrencyFetching || currencyError;
  const isFormHidden = isPaymentFetching || isNftFetching;

  const clearErrors = () => {
    paymentClear();
    nftClear();
    currencyClear();
    resetUpdated();
  }

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(currencyRates).length === 0) {
      getRates(defaultCurrency);
    }
  }, [currencyRates]);

  useEffect(() => {
    if (nftCountInStock === null || !!nftBought) {
      getNftCountInStock();
    }
  }, [nftBought, nftCountInStock]);

  useEffect(() => {
    if (paymentMethod && wallet) {
      buyNft(wallet, paymentMethod.id, nftPrice, currency);
    }
  }, [paymentMethod, wallet]);

  useEffect(() => {
    if (isError && messageRef?.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [paymentError, nftError, currencyError, userError]);

  useEffect(() => {
    if (nftBought && !user?.data?.nftOwner) {
      updateUserData({
        user: {
          data: {
            nftOwner: true,
          }
        }
      });
    }

    if (nftBought && pageRef?.current) {
      pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (nftBought) {
      getNftOwner(nftBought.owner);
    }
  }, [nftBought]);

  const onChangeCurrency = (currency) => {
    if (Object.keys(currencyRates).length === 0) {
      return;
    }

    setCurrency(currency);
    setNftPrice(calculatePrice(defaultNftPrice, currency, currencyRates));
  }

  if (isPaymentFetching) {
    message = 'Payment in progress…';
  }

  if (isNftFetching) {
    message = 'Allocating Digital Collectible…';
  }

  if (isUserFetching) {
    message = 'Updating User Information...';
  }

  if (paymentError) {
    message = getErrorMessage(paymentError);
  }

  if (nftError) {
    message = getErrorMessage(nftError);
  }

  if (userError) {
    message = 'Unable to update user information.';
  }

  if (currencyError) {
    message = 'Unable to receive currencies rates. Please try again later.';
  }

  const playVideo = () => {
    if (playerRef?.current) {
      playerRef.current.play();
      setRevealVideoPlaying(true);
    }
  };

  const nftPlayVideo = () => {
    if (nftPlayerRef?.current) {
      nftPlayerRef.current.play();
    }
  };

  const onVideoEnd = () => {
    setRevealVideoPlaying(false);
    setRevealVideoEnded(true);
    nftPlayVideo();
  }

  const onPaymentSubmit = (data) => {
    clearErrors();
    payByCard(data);
  }

  return (
    <>
      <section className="collection-nft -buy" ref={pageRef}>
        {!nftBought &&
          <Carousel>
            <Card src={require('../../i/nft-stills/england.jpg')} />
            <Card src={require('../../i/nft-stills/england_away.jpg')} />
            <Card src={require('../../i/nft-stills/brazil.jpg')} />
            <Card src={require('../../i/nft-stills/germany.jpg')} />
            <Card src={require('../../i/nft-stills/france.jpg')} />
            <Card src={require('../../i/nft-stills/spain.jpg')} />
            <Card src={require('../../i/nft-stills/usa.jpg')} />
            <Card src={require('../../i/nft-stills/mexico.jpg')} />
          </Carousel>
        }
        {nftBought && !isRevealVideoPlaying && !isRevealVideoEnded &&
        <div className="image">
          <img src={require('../../i/nft-reveal.jpg')} alt="reveal" />
        </div>
        }
        {nftBought &&
          <div className="image" style={{ display: isRevealVideoPlaying && !isRevealVideoEnded ? 'inline-block' : 'none' }}>
            <Video
              src={require('../../i/nft-reveal.mp4')}
              playerRef={playerRef}
              onVideoEnd={onVideoEnd}
              playsInline
            />
          </div>
        }
        {nftBought &&
          <div className="image" style={{ display: isRevealVideoEnded ? 'inline-block' : 'none' }}>
            <Video src={nftBought.animation_url} loop playerRef={nftPlayerRef} playsInline />
          </div>
        }
        <div className="info">
          <h1>UmbroID Collection</h1>

          { !nftBought && <PriceSelector currency={currency} price={nftPrice} onChangeCurrency={onChangeCurrency} />}
          <p>To commemorate Umbro’s iconic role in the beautiful game throughout the generations, and to celebrate the biggest festival in world football (the first to be staged in the Middle East), Umbro are launching their first ever 808 limited digital collectibles.</p>
          <p>There are 8 different designs. Which one will you get?</p>

          { message && <div ref={messageRef}><b>{message}</b></div> }
          { !nftBought &&
            <>
              <button type="button" disabled={true} className="-wide -disabled">Sale Ended</button>
              {/* <StripePaymentForm
                onSubmit={onPaymentSubmit}
                disabled={isFormDisabled}
                hidden={isFormHidden}
                nftCountInStock={nftCountInStock}
                user={user}
              /> */}
            </>
          }

          { !!nftBought && !isRevealVideoPlaying && !isRevealVideoEnded &&
            <div className="buttons" style={{ textAlign: 'center', padding: '32px 0' }}>
              <button className="-gradient -wide" onClick={playVideo}>Reveal</button>
            </div>
          }

          <div className="ntf-blocks">
            {nftOwner && <OwnerBlock owner={nftOwner} />}
            <CollectionBlock />
          </div>

        </div>
      </section>

      <CollectionDescription />
    </>
  );
};

const mapStateToProps = ({ user, payment, nft, currency }) => ({
  wallet: user.wallet,
  user: user.info,
  userError: user.error,
  isUserFetching: user.isFetching,
  paymentMethod: payment.paymentMethod,
  paymentError: payment.error,
  isPaymentFetching: payment.isFetching,
  nftBought: nft.nftBought,
  nftCountInStock: nft.nftCountInStock,
  isNftFetching: nft.isFetching,
  nftError: nft.error,
  nftOwner: nft.nftOwner,
  currencyRates: currency.rates,
  isCurrencyFetching: currency.isFetching,
  currencyError: currency.error,
});

const mapActionsToProps = dispatch => ({
  payByCard: (data) => dispatch(payByCard(data)),
  paymentClear: () => dispatch(paymentClear()),
  buyNft: (wallet, paymentMethodId, amount, currency) => dispatch(buyNft(wallet, paymentMethodId, amount, currency)),
  getNftOwner: (wallet) => dispatch(getNftOwner(wallet)),
  getNftCountInStock: () => dispatch(getNftCountInStock()),
  nftClear: () => dispatch(nftClear()),
  resetUpdated: () => dispatch(resetUpdated()),
  updateUserData: (data) => dispatch(updateUserData(data)),
  getRates: (from) => dispatch(getRates(from)),
  currencyClear: () => dispatch(currencyClear()),
});

export default connect(mapStateToProps, mapActionsToProps)(BuyNft);
