import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { checkWallet } from '../store/user/actions';
import Error from '../components/Error';

const withAuthentication = (Component) => {
    const Authenticate = (props) => {
        const { isLoggedOut, wallet, checkWallet, error } = props;

        useEffect(() => {
            if (!wallet) {
                checkWallet();
            }
        }, [wallet, isLoggedOut]);

        if (error && (!error.response || error.response.status !== 401)) {
            return <Error />;
        }

        return wallet ? (
          <Component {...props} />
        ) : null;
    };

    const mapStateToProps = ({ auth, user }) => ({
        isLoggedOut: auth.isLoggedOut,
        wallet: user.wallet,
        error: user.error,
    });

    const mapActionsToProps = dispatch => ({
        checkWallet: () => dispatch(checkWallet()),
    });

    return connect(mapStateToProps, mapActionsToProps)(Authenticate);
};

export default withAuthentication;
