import { PAYMENT_PAY_BY_CARD, PAYMENT_CLEAR } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    paymentMethod: null,
    error: null,
    isFetching: false,
};

export const paymentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PAYMENT_PAY_BY_CARD + API_REQUEST:
            return { ...state, isFetching: true };
        case PAYMENT_PAY_BY_CARD + API_SUCCESS:
            return {
                ...state,
                paymentMethod: payload,
                isFetching: false,
            };
        case PAYMENT_PAY_BY_CARD + API_ERROR:
            return {
                ...state,
                paymentMethod: null,
                isFetching: false,
                error: payload,
            };
        case PAYMENT_CLEAR:
            return {
                ...state,
                paymentMethod: null,
                error: null,
            };
        default:
            return state;
    }
};
