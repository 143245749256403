import { CURRENCY_RATES } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    rates: {},
    error: null,
    isFetching: false,
};

export const currencyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CURRENCY_RATES + API_REQUEST:
            return { ...state, isFetching: true };
        case CURRENCY_RATES + API_SUCCESS:
            return { ...state, rates: payload, isFetching: false };
        case CURRENCY_RATES + API_ERROR:
            return { ...state, isFetching: false, error: payload };
        default:
            return state;
    }
};
