import './local.scss';
import { useEffect } from 'react';
import { getNft, getNftOwner } from '../../store/nft/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import Video from '../../components/Video/Video';

import CollectionBlock from '../../layout/CollectionBlock';
import OwnerBlock from '../../layout/OwnerBlock';
import CollectionDescription from '../../layout/CollectionDescription';
import Error from '../../components/Error';

const Nft = ({ nft, nftOwner, nftError, getNft, getNftOwner }) => {
  const { id } = useParams();
  const { animation_url, name, description, owner } = nft || {};

  useEffect(() => {
    getNft(id);
  }, [id]);

  useEffect(() => {
    if (owner) {
      getNftOwner(owner);
    }
  }, [owner]);

  if (!nft) {
    return null;
  }

  if (nftError) {
    return <Error />;
  }

  return (
    <>
      <Helmet>
        <title>Check out this Digital Collectible on DNIZN</title>
        <meta name="description" content="Part of Umbro’s first ever web3 collection" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Check out this Digital Collectible on DNIZN" />
        <meta
          property="og:description"
          content="Part of Umbro’s first ever web3 collection"
        />
        <meta property="og:url" content={`https://dnizn.com/nft/${id}`} />
        <meta property="og:image" content="https://dnizn.com/og.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dnizn.com" />
        <meta property="twitter:url" content={`https://dnizn.com/nft/${id}`} />
        <meta name="twitter:title" content="Check out this Digital Collectible on DNIZN" />
        <meta name="twitter:description" content="Part of Umbro’s first ever web3 collection" />
        <meta name="twitter:image" content="https://dnizn.com/og.png" />
      </Helmet>
      <section className="collection-nft">
        <div className="image">
          <div>
            <Video src={animation_url} autoPlay muted loop playsInline />
          </div>
        </div>
        <div className="info">
          <h1>{name}</h1>
          <p>{description}</p>
          
          <div className="nft-blocks">
            {nftOwner && <OwnerBlock owner={nftOwner} />}
            <CollectionBlock />
          </div>

        </div>
      </section>

      <CollectionDescription />
    </>
  );
};

const mapStateToProps = ({ nft }) => ({
  nft: nft.nft,
  nftOwner: nft.nftOwner,
  nftError: nft.error,
});

const mapActionsToProps = dispatch => ({
  getNft: (id) => dispatch(getNft(id)),
  getNftOwner: (wallet) => dispatch(getNftOwner(wallet)),
});

export default connect(mapStateToProps, mapActionsToProps)(Nft);
