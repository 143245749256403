import { useEffect, useState } from 'react';
import  { connect } from 'react-redux';
import EasyAES from "easy-aes";
import { authFirstUrl, authSecondUrl } from '../../helpers/authUrl';
import { fetchUser } from '../../store/user/actions';
import { addSpecialRole } from '../../store/discord/actions';
import { getRedirectTo, setRedirectToLastUrl } from '../../helpers/authRedirect';
import { useSearchParams } from 'react-router-dom';
import NftError from '../nft/NftError';

const STATE_DISCORD_USER_PREFIX = 'discord:user:';
const aes = new EasyAES(process.env.REACT_APP_CRYPTR_SECRET);

const OauthMiddleware = ({
  wallet,
  user,
  isWalletRequested,
  isLoggedOut,
  isRoleAdded,
  roleError,
  fetchUser,
  addSpecialRole
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [isParentRedirect, setParentRedirect] = useState(false);
  const [search] = useSearchParams();

  let state;

  try {
    state = search.get('state') ? aes.decrypt(search.get('state')) : undefined;
  } catch (error) {
    console.log(error);
  }

  const isDiscord = wallet && state && state.includes(STATE_DISCORD_USER_PREFIX);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isLoggedOut) {
      window.location.href = authFirstUrl();
    } else if (!wallet && isWalletRequested) {
      window.location.href = authSecondUrl();
    } else if (isDiscord && !isRoleAdded && !roleError && !!user.data.nftOwner) {
      addSpecialRole(state.replace(STATE_DISCORD_USER_PREFIX, ''));
    } else if (isDiscord && (isRoleAdded || !user.data.nftOwner)) {
      setRedirectTo('/nft/owner');
    } else if (wallet && !isDiscord) {
      setRedirectToLastUrl(setRedirectTo, setParentRedirect);
    }
  }, [wallet, user, isWalletRequested, isLoggedOut, isRoleAdded, state, roleError]);

  if (isDiscord && roleError) {
    return <NftError />;
  }

  if (redirectTo !== null) {
    return getRedirectTo(redirectTo, isParentRedirect);
  }

  return null;
};

const mapStateToProps = ({ auth, user, discord }) => ({
  wallet: user.wallet,
  user: user.info,
  isWalletRequested: user.isWalletRequested,
  isLoggedOut: auth.isLoggedOut,
  isRoleAdded: discord.isRoleAdded,
  roleError: discord.error,
});

const mapActionsToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  addSpecialRole: (userId) => dispatch(addSpecialRole(userId)),
});

export default connect(mapStateToProps,mapActionsToProps)(OauthMiddleware);
