import { DISCORD_ADD_SPECIAL_ROLE } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    isRoleAdded: false,
    error: null,
    isFetching: false,
};

export const discordReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case DISCORD_ADD_SPECIAL_ROLE + API_REQUEST:
            return { ...state, isRoleAdded: false, isFetching: true };
        case DISCORD_ADD_SPECIAL_ROLE + API_SUCCESS:
            return { ...state, isRoleAdded: true, isFetching: false };
        case DISCORD_ADD_SPECIAL_ROLE + API_ERROR:
            return { ...state, isRoleAdded: false, isFetching: false, error: payload };
        default:
            return state;
    }
};
