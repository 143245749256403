const Error = ({ message = "Something went wrong, please try again later." }) => {
  return (
    <>
      <section>
        <h3 style={{ textAlign: 'center' }}>{message}</h3>
      </section>
    </>
  );
};

export default Error;
