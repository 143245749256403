import { axiosAuth } from '../helpers/axios';
import { getToken } from './storage';
import { TOKEN_ID_FIRST } from '../pages/oauth2/constants';

const baseUrl = process.env.REACT_APP_MAILCHIMP_API_URL;

export const subscribe = (email) => {
  return axiosAuth(baseUrl, getToken(TOKEN_ID_FIRST)).post('/subscribe', {
    email,
  }).then(response => response.data || {});
}

export const unsubscribe = () => {
  return axiosAuth(baseUrl, getToken(TOKEN_ID_FIRST)).post('/unsubscribe').then(response => response.data || {});
}

export const getInfo = () => {
  return axiosAuth(baseUrl, getToken(TOKEN_ID_FIRST)).get('/info').then(response => response.data || {});
}
